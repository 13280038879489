import { Form } from '@unform/web';
import styled from 'styled-components';

interface CommentProps {
  id: number;
}

export const Container = styled.div`
  .tableWrapper {
    .tableWrapperBox {
      max-height: 250px;
      overflow: auto;
    }
  }

  .detailsItem {
    img {
      margin-right: 5px;
    }
  }

  .table-box {
    .orderImage {
      display: flex;
      justify-content: center;
      img {
        max-width: 40px;
        max-height: 35px;
      }
    }
  }

  .attachmentSection {
    padding: 0rem 1rem 0.5rem;
    img {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      margin-right: 1rem;
    }
  }

  .wrapper {
    padding: 0rem 1rem;
    .details {
      display: flex;
      flex-direction: column;
      background: rgba(245, 248, 250, 0.4);
      border: 1px dashed #e4e6ef;
      border-radius: 6px;
      margin-bottom: 1rem;

      .detailsContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .detailsItemsContainer {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin: 12px 0 20px 15px;
          gap: 3rem;
          flex-wrap: wrap;
          padding: 0 20px;

          #redValue {
            p:last-of-type {
              color: #f64e60;
            }
          }

          .totalTimeResume {
            .detailsItem {
              p:last-of-type {
                color: #46be7d;
                font-weight: 600;
              }
            }
          }

          .detailsItem {
            :last-of-type {
              display: flex;
              flex-direction: column;
            }
            p {
              display: flex;
              align-items: center;
              :first-of-type {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 12px;
                color: #7e8299;
              }
              :last-of-type {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 18px;
                color: #3f4254;
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }

  .detailHeaderDivider {
    margin: 0.8rem 0 1.5rem;
    p {
      margin-left: 2rem;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;

      display: flex;
      align-items: center;

      color: #040404;
    }
  }

  .commentSection {
    padding: 0 1rem;
    margin-bottom: 1rem;
    max-height: 320px;
    overflow-y: auto;
  }

  .comment {
    padding: 1.5rem;
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;
    border: 1px solid #eaeef4;
    border-radius: 5px;
  }

  .userInfo {
    display: flex;
    align-items: center;
    gap: 12px;

    p {
      font-weight: 600;
    }
  }

  .message {
    margin-top: 1.5rem;
    p {
      font-weight: 400;
    }
  }

  .date {
    margin-top: 1.5rem;
    align-self: flex-end;
    font-size: 12px;
    color: #888;
  }
`;

export const ModalContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .wrapperComments {
    display: flex;
    width: 100%;
    padding: 0 16px;
    flex: 1;
  }

  .textAreaContainer {
    display: flex;
    width: 65%;
    flex-direction: column;
  }

  .statusSelectContainer {
    width: 35%;
    margin-left: 1rem;
  }

  label {
    font-size: 12px;
    line-height: 18px;
    color: #464e5f;
    text-align: left;
  }

  .submitButton {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 13px 16px;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin: 16px;
    color: #fff;
    background-color: #46be7d;
    align-self: flex-end;
    svg {
      margin-right: 10px;
    }
    &:hover {
      background-color: #e8fff3;
      transition: 0.5s;
      box-shadow: none;
      p {
        color: #46be7d !important;
      }
      path {
        fill: #46be7d;
      }
    }
  }

  @media (max-width: 640px) {
    .textAreaContainer {
      display: flex;
      width: 65%;
      flex-direction: column;
    }
    .wrapperComments {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 16px;
      flex: 1;
    }
  }
`;

export const Comment = styled.div<CommentProps>`
  padding: 1.5rem;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  border: 1px solid #eaeef4;
  border-radius: 5px;
  background-color: ${props => (props.id % 2 === 0 ? '#EBF9F5' : '#FFFFFF')};

  .userInfo {
    display: flex;
    align-items: center;
    gap: 12px;

    p {
      font-weight: 600;
    }
  }

  .message {
    margin-top: 1.5rem;
    p {
      font-weight: 400;
    }
  }

  .date {
    margin-top: 1.5rem;
    align-self: flex-end;
    font-size: 12px;
    color: #888;
  }
`;
