import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import PageMode from '../../../../components/PageMode';
import { useToast } from '../../../../hooks/Toast';
import { handleCreateStoreData, handleEditStoreData } from '../api/Store.api';
import { IEditStoreProps } from '../types';

const EditStore: React.FC<IEditStoreProps> = ({
  isOpen,
  handleClose,
  rechargeStoreData,
  typesStore,
  currentValue,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const editInputsRef = useRef<FormHandles>(null);
  const { Toastsuccess, ToastError } = useToast();

  const hanldleCreate = useCallback(
    (data: any) => {
      handleEditStoreData(currentValue.id, data)
        .then(() => {
          Toastsuccess({
            message: `Loja editada com sucesso!`,
          });
        })
        .catch(err => {
          ToastError({ message: `Ops falha ao editar loja ${err}!` });
          handleClose('edit');
        })
        .finally(() => {
          rechargeStoreData({
            search: null,
            is_workflow: false,
          });
          handleClose('edit');
          setIsModalLoading(false);
        });
    },
    [ToastError, Toastsuccess, currentValue.id, handleClose, rechargeStoreData]
  );

  const handleValueCurrent = useCallback(() => {
    editInputsRef.current?.setFieldValue('idFeed', currentValue.feed);
    editInputsRef.current?.setFieldValue('name', currentValue.name);
    editInputsRef.current?.setFieldValue('is_workflow', {
      label: currentValue.is_workflow === true ? 'Reduzido' : 'Normal',
      value: currentValue.is_workflow,
    });
  }, [currentValue.feed, currentValue.is_workflow, currentValue.name]);

  useEffect(() => {
    handleValueCurrent();
  }, [handleValueCurrent, isOpen]);

  return (
    <PageMode
      isModal
      open={isOpen}
      modalLoading={isModalLoading}
      handleClose={() => handleClose('edit')}
      modalRefObject={editInputsRef}
      submitButtonTitle="Salvar"
      submitButtonIcon
      title={`Editar Loja ${currentValue.name}`}
      handleSubmit={data => hanldleCreate(data)}
      inputs={[
        {
          name: 'name',
          label: 'Nome',
          placeholder: 'Nome da loja',
          type: 'text',
          xl: 4,
          lg: 4,
          xs: 12,
          md: 6,
          sm: 6,
        },
        {
          name: 'idFeed',
          label: 'ID Feed AJ',
          placeholder: 'ID Feed AJ',
          type: 'text',
          xl: 4,
          lg: 4,
          xs: 12,
          md: 6,
          sm: 6,
        },
        {
          name: 'is_workflow',
          label: 'Tipo Fluxo',
          placeholder: 'Tipo Fluxo',
          isRequired: true,
          type: 'select',
          options: typesStore,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 6,
          sm: 6,
        },
      ]}
    />
  );
};
export default EditStore;
