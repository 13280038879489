import api from '../../../services/api';
import { transformToSelect } from '../../../utils/toSelect';

export const handleSearchTickets = async (data?: any) => {
  const params = {
    page: 1,
    perPage: 2000,
    order: data?.order || null,
    documentCpf: data?.documentCpf || null,
    clientName: data?.clientName || null,
    branchId: data?.loja || null,
    creationDateStart: data?.initialDate || null,
    creationDateEnd: data?.finalDate || null,
    status: data?.status || null,
    categoryId: data?.categoryId || null,
    sectorId: data?.sectorId || null,
  };

  return api.get('/tickets/search', {
    params,
  });
};

export const handleEditTicket = async (data?: any) => {
  return api.put(`/tickets/v2/${data.id}`, data);
};

export const getSelectOptions = async () => {
  const [statusRes, lojaRes, categoriesRes, sectorsRes] = await Promise.all([
    api.get('/ticketStatus'),
    api.get('/branch'),
    api.get('/categories'),
    api.get('/sectors'),
  ]);

  const options = lojaRes.data.map((res: any) => {
    const store = {
      description: res.name,
      branch_code: res.code,
      id: res.id,
    };
    return store;
  });

  const statusOptions = statusRes.data.map((res: any) => {
    const status = {
      description: res.description,
      id: res.id,
    };
    return status;
  });

  const categoriesOptions = categoriesRes.data.map((res: any) => {
    const category = {
      description: res.description,
      id: res.id,
    };
    return category;
  });

  const sectorsOptions = sectorsRes.data.map((res: any) => {
    const sector = {
      description: res.description,
      id: res.id,
    };
    return sector;
  });

  const formatedStatus = transformToSelect(statusOptions);
  const formatedLojas = transformToSelect(options);
  const formatedCategories = transformToSelect(categoriesOptions);
  const formatedSectors = transformToSelect(sectorsOptions);

  return [formatedStatus, formatedLojas, formatedCategories, formatedSectors];
};

export const getDetailData = (ticketId: number) =>
  api.get(`/tickets/v2/${ticketId}`);
