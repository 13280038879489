import React, { useEffect, useState, useCallback } from 'react';
import { BsHouse } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { useToast } from '../../../hooks/Toast';

import {
  ButtonLink,
  Container as PageListStyle,
} from '../../../styles/PageStyles/List';

import Breadcrumb from '../../../components/Breadcrumb';
import SearchBoxComponent from '../../../components/SearchBox';
import PopUpWindow from '../../../components/PopUpWindow';
import Table from '../../../components/Table';
import LoadingComponent from '../../../components/LoadingComponent';
import TitleWithButtons from '../../../components/TitleWithButtons';

import api from '../../../services/api';

interface UserItem {
  codidt: number;
  username: string;
  fullname?: string;
  password: string;
  email?: string;
  roles?: string;
  pic?: string;
  dtInclusao?: Date;
  dtAlteracao?: Date;
  ativo: boolean;
}

const Users: React.FC = () => {
  const [users, setUsers] = useState([] as UserItem[]);
  const [loading, setLoading] = useState(true);
  const [deleteUser, setDeleteUser] = useState(NaN as number);

  const { ToastError, Toastsuccess } = useToast();
  const history = useHistory();

  useEffect(() => {
    async function getUsers() {
      try {
        setLoading(true);

        const data = {};

        const userResponse = await api.get('TbUsers', {
          ...data,
        });

        setUsers([...userResponse.data]);
      } catch (err) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    }

    getUsers();
  }, [ToastError]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        const userResponse = await api.get('TbUsers', {
          ...data,
        });

        setUsers([...userResponse.data]);

        Toastsuccess({
          message: 'Pesquisa feita com sucesso!',
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    },
    [Toastsuccess, ToastError]
  );

  const handleDeleteUser = useCallback(async () => {
    try {
      if (!deleteUser) {
        throw new Error('Usuário inexistente');
      }

      await api.delete(`TbUsers/${deleteUser}`);

      Toastsuccess({
        message: `Usuário ${deleteUser} deletado com sucesso!`,
      });

      setDeleteUser(NaN);
    } catch (error) {
      ToastError({
        message: 'Ocorreu um erro',
      });
    }
  }, [Toastsuccess, ToastError, deleteUser]);

  const deleteThisUser = useCallback(code => {
    setDeleteUser(code);
  }, []);

  const editUser = useCallback(
    code => {
      history.push(`/User/edit/${code}`);
    },
    [history]
  );

  return (
    <PageListStyle>
      <Breadcrumb title="Usuários" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Lista`}
      </Breadcrumb>

      {!!deleteUser && (
        <PopUpWindow
          title="Deseja deletar?"
          handleSubmit={handleDeleteUser}
          handleCancel={() => setDeleteUser(NaN)}
        >
          Você tem certeza que deseja deletar?
        </PopUpWindow>
      )}

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Lista de Usuários"
              filterTitle="Opções de Filtro"
              filter
              filterContent={() => (
                <SearchBoxComponent
                  handleSubmit={handleSubmit}
                  justifycontent="flex-start"
                  inputs={[
                    {
                      width: 50,
                      label: 'nome:',
                      name: 'name',
                      placeholder: 'Digite o nome do nome',
                      messageErrorOnBlur: 'Digite um nome de nome',
                    },
                    {
                      width: 50,
                      label: 'e-mail:',
                      name: 'email',
                      placeholder: 'Digite o e-mail',
                      messageErrorOnBlur: 'Digite um e-mail',
                    },
                  ]}
                />
              )}
            >
              <ButtonLink to="/User/create" type="button">
                Novo Usuário
              </ButtonLink>
            </TitleWithButtons>

            {loading ? (
              <LoadingComponent />
            ) : (
              <div className="table-box">
                <Table<UserItem>
                  rows={[
                    ...users.map(item => ({
                      ...item,
                    })),
                  ]}
                  columns={[
                    {
                      title: 'Código',
                      orderable: true,
                      type: 'number',
                      cssProps: {
                        width: '10%',
                      },
                      props: ['codidt'],
                      renderItem: row => {
                        return (
                          <div className="code-item">
                            <p>{row.codidt}</p>
                          </div>
                        );
                      },
                    },
                    {
                      title: 'Nome',
                      orderable: true,
                      type: 'string',
                      props: ['fullname'],
                    },
                    {
                      title: 'Login',
                      orderable: true,
                      type: 'string',
                      props: ['username'],
                      cssProps: {
                        width: '15%',
                      },
                    },
                    {
                      title: 'e-mail',
                      orderable: true,
                      type: 'string',
                      props: ['email'],
                    },
                    {
                      title: 'Perfil',
                      orderable: true,
                      type: 'string',
                      props: ['roles'],
                      renderItem: row => {
                        return (
                          <div className="text">
                            <p>{row.roles}</p>
                          </div>
                        );
                      },
                    },
                    {
                      title: 'Status',
                      orderable: true,
                      type: 'boolean',
                      props: ['ativo'],
                    },
                  ]}
                  paginationOptions={[
                    {
                      value: 5,
                      label: 5,
                    },
                    {
                      value: 10,
                      label: 10,
                    },
                    {
                      value: 15,
                      label: 15,
                    },
                    {
                      value: 25,
                      label: 25,
                    },
                  ]}
                  defaultSort="fullname"
                  onDeleteRow={row => {
                    deleteThisUser(row.codidt);
                  }}
                  onEditRow={row => {
                    editUser(row.codidt);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageListStyle>
  );
};

export default Users;
