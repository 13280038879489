import React, { useCallback, useRef, useState } from 'react';
import {
  Badge,
  Button,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { FilterButtons, FilterContainer } from '../styles';
import searchIcon from '../../../assets/svg/searchIcon.svg';
import filterIcon from '../../../assets/svg/filterIcon.svg';
import { Checkbox, DatePicker, Input, Select, SwitchButton } from '../../Form';
import { InputProps } from '../index';

interface FilterProps {
  hiddenInputs?: InputProps[];
  inputs: InputProps[];
  handleSubmit?(data: object): void;
  searchDisabled?: boolean;
  searchRef?: React.RefObject<FormHandles>;
  clearButtonTitle?: string;
  submitButtonTitle?: string;
  eventClearButton?: (e?: any) => void;
}

const Filter: React.FC<FilterProps> = ({
  inputs,
  hiddenInputs,
  handleSubmit,
  searchDisabled,
  searchRef,
  clearButtonTitle,
  submitButtonTitle,
  eventClearButton,
}) => {
  const ownRef = useRef<FormHandles>(null);
  const formRef = searchRef || ownRef;
  const [advancedSearch, setAdvancedSearch] = useState(false);

  React.useEffect(() => {
    setAdvancedSearch(false);
  }, []);

  const handleInputError = useCallback(
    ({ inputName, message }) => {
      if (formRef) {
        formRef.current?.setFieldError(inputName, message);
      }
    },
    [formRef]
  );

  const handleShowAdvancedSearch = () => {
    setAdvancedSearch(!advancedSearch);
  };

  const handleEventClearButton = useCallback(
    (event: any) => {
      if (eventClearButton) {
        eventClearButton(event);
      }
    },
    [eventClearButton]
  );

  const clearFields = useCallback(() => {
    inputs.map(input => formRef.current?.setFieldValue(input.name, ''));
    hiddenInputs?.map(input => formRef.current?.setFieldValue(input.name, ''));
  }, [inputs, formRef, hiddenInputs]);

  const onSubmit = useCallback(
    data => {
      if (handleSubmit) {
        handleSubmit(data);
      }
    },
    [handleSubmit]
  );

  const switchType = (input: InputProps) => {
    switch (input.type) {
      case 'text':
        return (
          <Input
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type={input.type || 'text'}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            defaultValue={input.defaultValue}
            // iconError={IconError}
            // iconSuccess={IconSuccess}
          />
        );
      case 'select':
        return (
          <Select
            name={input.name}
            key={input.name.toString()}
            options={input.options}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            isLoading={input.isLoading}
            isDisabled={input.isDisabled}
            styles={input.styles}
            onChangeEvent={input.onChangeEvent}
            isApiSearch={input.isApiSearch}
            // isMulti={isMulti}
          />
        );
      case 'date':
        return (
          <DatePicker
            id={input.name}
            name={input.name}
            type="date"
            key={input.name.toString()}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            defaultValue={input.defaultValue}
          />
        );
      case 'switch':
        return (
          <SwitchButton
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type="checkbox"
            placeholder={input.placeholder}
            defaultValue={input.defaultValue}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            id={input.name}
            name={input.name}
            type="checkbox"
            key={input.name.toString()}
            label={input.labelCheckbox}
            placeholder={input.placeholder}
            onClickEvent={input.onClickEvent}
            defaultValue={input.defaultValue}
          />
        );
      default:
        return (
          <Input
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type={input.type || 'text'}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            defaultValue={input.defaultValue}

            // iconError={IconError}
            // iconSuccess={IconSuccess}
          />
        );
    }
  };

  return (
    <Form onSubmit={onSubmit} ref={formRef}>
      <FilterContainer>
        <Grid
          style={{ padding: '1.5rem 1rem', alignItems: 'self-end' }}
          container
          spacing={3}
        >
          {inputs.map(
            ({
              name,
              label,
              type = 'text',
              options,
              placeholder,
              width = 3,
              sm = 12,
              xl = width,
              lg = width,
              xs = 12,
              md = 12,
              isRequired,
              isLoading,
              isDisabled,
              styles,
              onClickEvent,
              labelCheckbox,
              onChangeEvent,
              display,
              isMulti,
              ...rest
            }) => (
              <>
                {display ?? false ? null : (
                  <Grid
                    item
                    xl={xl}
                    lg={lg}
                    xs={xs}
                    md={md}
                    sm={sm}
                    key={name.toString()}
                  >
                    {!(label == null) && (isRequired ?? false) ? (
                      <Badge badgeContent="*">
                        <p className="labelInput">{label}</p>
                      </Badge>
                    ) : (
                      <p className="labelInput">{label}</p>
                    )}
                    {switchType({
                      name,
                      label,
                      type,
                      options,
                      placeholder,
                      width,
                      sm,
                      xl,
                      lg,
                      xs,
                      md,
                      isRequired,
                      isLoading,
                      isDisabled,
                      styles,
                      onClickEvent,
                      labelCheckbox,
                      onChangeEvent,
                      isMulti,
                      ...rest,
                    })}
                  </Grid>
                )}
              </>
            )
          )}
          <div
            style={{
              width: '100%',
            }}
          >
            <Collapse in={advancedSearch} timeout={800}>
              <Grid
                style={{
                  padding: '0 0.7rem',
                  alignItems: 'self-end',
                }}
                container
                spacing={3}
                className="advancedFilterContainer"
              >
                {hiddenInputs?.map(
                  ({
                    name,
                    label,
                    type = 'text',
                    options,
                    placeholder,
                    width = 3,
                    sm = 12,
                    xl = width,
                    lg = width,
                    xs = 12,
                    md = 12,
                    isRequired,
                    isLoading,
                    isDisabled,
                    styles,
                    onClickEvent,
                    labelCheckbox,
                    onChangeEvent,
                    display,
                    isMulti,
                    ...rest
                  }) => (
                    <>
                      {display ?? false ? null : (
                        <Grid
                          item
                          xl={xl}
                          lg={lg}
                          xs={xs}
                          md={md}
                          sm={sm}
                          key={name.toString()}
                        >
                          {!(label == null) && (isRequired ?? false) ? (
                            <Badge badgeContent="*">
                              <p className="labelInput">{label}</p>
                            </Badge>
                          ) : (
                            <p className="labelInput">{label}</p>
                          )}
                          {switchType({
                            name,
                            label,
                            type,
                            options,
                            placeholder,
                            width,
                            sm,
                            xl,
                            lg,
                            xs,
                            md,
                            isRequired,
                            isLoading,
                            isDisabled,
                            styles,
                            onClickEvent,
                            labelCheckbox,
                            onChangeEvent,
                            isMulti,
                            ...rest,
                          })}
                        </Grid>
                      )}
                    </>
                  )
                )}
              </Grid>
            </Collapse>
          </div>
        </Grid>
        <FilterButtons>
          <Button
            className="clearButton"
            onClick={e => {
              handleEventClearButton(e);
              clearFields();
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.77467 15.3334L0.195333 11.7567C0.0653334 11.6267 0 11.4567 0 11.2854C0 11.1147 0.0653334 10.944 0.195333 10.814L10.148 0.86202C10.278 0.732687 10.4487 0.666687 10.6193 0.666687C10.7893 0.666687 10.9607 0.732687 11.0907 0.86202L15.8047 5.57735C15.9353 5.70735 16 5.87802 16 6.04935C16 6.22002 15.9353 6.39002 15.8047 6.52069L8.32733 14H12.0033V15.3334H3.77467ZM6.21267 14L2.55533 10.3427L1.60933 11.2854L4.32667 14H6.21267ZM10.616 2.27935L3.498 9.39935L7.27 13.172L14.3873 6.05069L10.616 2.27935Z"
                fill="#D9214E"
              />
            </svg>
            <p>{clearButtonTitle || 'Limpar'}</p>
          </Button>
          {hiddenInputs !== undefined &&
            (advancedSearch === true ? (
              <Button
                className="closeFilterButton"
                onClick={() => handleShowAdvancedSearch()}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.986 1.33333L8 9.69533V13.6447L7.33333 13.2447V9.69533L2.34733 1.33333H12.986ZM15.3333 0H0L6 10.0627V14L9.33333 16V10.0627L15.3333 0Z"
                    fill="#F69B11"
                  />
                </svg>
                <p>Menos filtros</p>
              </Button>
            ) : (
              <Button
                className="advancedFilterButton"
                onClick={() => handleShowAdvancedSearch()}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.986 1.33333L8 9.69533V13.6447L7.33333 13.2447V9.69533L2.34733 1.33333H12.986ZM15.3333 0H0L6 10.0627V14L9.33333 16V10.0627L15.3333 0Z"
                    fill="#46BE7D"
                  />
                </svg>
                <p>Filtros Avançados</p>
              </Button>
            ))}
          <Button
            className="searchButton"
            type="submit"
            variant="contained"
            disabled={searchDisabled}
          >
            {searchDisabled ? (
              <CircularProgress
                style={{ width: '16px', height: '16px', color: '#0095E8' }}
              />
            ) : (
              <img src={searchIcon} alt="searchIcon" />
            )}
            <p>
              {searchDisabled ? 'Carregando' : submitButtonTitle || 'Pesquisar'}
            </p>
          </Button>
        </FilterButtons>
      </FilterContainer>
    </Form>
  );
};

export default Filter;
