/* eslint-disable react/jsx-curly-newline */
import React, {
  useCallback,
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react';
import { FormHandles } from '@unform/core';

import {
  searchBoxRequest,
  getSelectOptions,
  getDetailData,
  postResetOrdemData,
} from './api/orders.api';

import {
  IOrderInputs,
  IOrder,
  OrderDetailProps,
  IDescriptionStoreProps,
} from './types';
import { OrdersTable } from './components';
import { Container } from './styles/styles';
import SearchBox from '../../components/Search';
import LoadingComponent from '../../components/LoadingComponent';
import { useToast } from '../../hooks/Toast';
import PageMode from '../../components/PageMode';
import OrderDetailsTable from './components/OrderDetailsTable';
import TableGridMode from '../../components/TableGridMode';
import ImportOrdersModal from './components/ImportOrdersModal';

type ViewModeProps = {
  mode: 'table' | 'grid';
};

const Orders: React.FC = () => {
  const [tableViewMode, setTableViewMode] = useState<ViewModeProps>({
    mode: 'table',
  });
  const [status, setStatus] = useState([]);
  const [gridStatus, setGridStatus] = useState([]);
  const [subsidiarys, setSubsidiarys] = useState<IDescriptionStoreProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [disabledStore, setDesabledStore] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showOrderSignatureModal, setShowOrderSignatureModal] = useState(false);

  const [signatureUrl, setSignatureUrl] = useState<string>('');
  const [ordersState, setOrdersState] = useState<IOrder[]>([]);
  const [orderDetailInformation, setOrderDetailInformation] = useState<IOrder>(
    {} as IOrder
  );
  const [orderDetailsData, setOrderDetailsData] = useState<OrderDetailProps[]>(
    []
  );
  const [eventClear, setEventClear] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const idStore = localStorage.getItem('@pdamodules::storeId');

  const typeOptions = [
    { code: '1', description: 'Retira', label: 'Retira', value: 'Retira' },
    { code: '2', description: 'Envio', label: 'Envio', value: 'Envio' },
  ];
  const ruptureOptions = [
    { code: '1', description: 'Sim', label: 'Sim', value: true },
    { code: '2', description: 'Não', label: 'Não', value: false },
  ];

  const { Toastsuccess, ToastError } = useToast();
  const searchBoxRef = useRef<FormHandles>(null);

  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const dateDay = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const clearAllFields = useCallback(() => {
    searchBoxRef.current?.setFieldValue('status', { value: '', label: '' });
    searchBoxRef.current?.setFieldValue('initialDate', '');
    searchBoxRef.current?.setFieldValue('finalDate', '');
    searchBoxRef.current?.setFieldValue('pedido', '');
    searchBoxRef.current?.setFieldValue('loja', '');
  }, []);
  const descriptionStore = subsidiarys.filter(
    store => store.value === Number(idStore)
  );
  const storeId = descriptionStore.map(res => res.value);
  const handleStoreInput = useCallback(() => {
    if (idStore !== null) {
      setDesabledStore(true);
      searchBoxRef.current?.setFieldValue('loja', {
        value: String(storeId),
        label: descriptionStore.map(res => res.label),
      });
    }
  }, [descriptionStore, idStore, storeId]);

  const handleSearchBoxSubmit = useCallback(
    async (formData?: IOrderInputs, clearFieldsAfterSearch?: boolean) => {
      setIsLoading(true);
      setShowTable(false);
      await searchBoxRequest(formData)
        .then(({ data }) => {
          if (!data.length) {
            setOrdersState([]);
            ToastError({ message: 'Não foram encontrados pedido(s)' });
          } else {
            setOrdersState(data);
            Toastsuccess({
              message: `${data.length} pedido(s) encontrados`,
            });
            setShowTable(true);
          }

          if (clearFieldsAfterSearch) clearAllFields();
        })
        .catch(err => {
          ToastError({ message: err });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clearAllFields, ToastError, Toastsuccess]
  );

  const handleDetail = async (row: IOrder) => {
    setModalLoading(true);
    setOpen(true);

    setOrderDetailInformation(row);

    await getDetailData(row.branchId, row.orderId).then(response => {
      if (response.data.length === 0) {
        ToastError({ message: 'Resultados não encontrados' });
      } else {
        setOrderDetailsData(response.data.items);
      }
    });

    setModalLoading(false);
  };

  const handleReset = async (row: IOrder) => {
    try {
      await postResetOrdemData(row.branchId, row.id).then(response => {
        Toastsuccess({ message: 'Ordem resetada com sucesso.' });
      });
    } catch (err) {
      ToastError({
        message: 'Ocorreu um erro para resetar ordem.',
      });
    }
  };

  const handleOpenSignatureModal = (url: string | null) => {
    setShowOrderSignatureModal(true);
    setModalLoading(true);
    if (url) {
      setSignatureUrl(url);
    }
  };

  const handleCloseSignatureModal = () => {
    setShowOrderSignatureModal(false);
    setSignatureUrl('');
  };

  const getAllOptions = useCallback(async () => {
    const [statusRes, lojasRes] = await getSelectOptions();

    setStatus(statusRes);
    setSubsidiarys(lojasRes);
  }, []);

  const setInitialFields = useCallback(() => {
    searchBoxRef.current?.setFieldValue('initialDate', dateDay);
    searchBoxRef.current?.setFieldValue('finalDate', dateDay);
    handleSearchBoxSubmit({
      initialDate: dateDay,
      finalDate: dateDay,
      loja: idStore,
    });
  }, [dateDay, handleSearchBoxSubmit, idStore]);

  const toggleViewMode = () => {
    if (tableViewMode.mode === 'table') {
      setTableViewMode({ mode: 'grid' });
    } else {
      setTableViewMode({ mode: 'table' });
    }
  };

  useLayoutEffect(() => {
    getAllOptions();
  }, [getAllOptions]);

  useEffect(() => {
    setInitialFields();
  }, [setInitialFields]);

  useEffect(() => {
    if (descriptionStore.length) {
      handleStoreInput();
    }
  }, [handleStoreInput, eventClear, descriptionStore]);

  return (
    <>
      <Container withOutPadding>
        <SearchBox
          handleSubmit={data => handleSearchBoxSubmit(data)}
          searchBoxRef={searchBoxRef}
          searchDisabled={isLoading}
          eventClear={e => setEventClear(e && !eventClear)}
          importButton={() => setShowImportModal(true)}
          inputs={[
            {
              name: 'orderNumber',
              label: 'Número do pedido',
              placeholder: 'Digite o número do pedido',
              type: 'text',
              xl: 4,
              lg: 4,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'loja',
              label: 'Loja',
              placeholder: 'Selecione a Loja',
              type: 'select',
              options: descriptionStore.length ? descriptionStore : subsidiarys,
              isDisabled: disabledStore,
              xl: 4,
              lg: 4,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'status',
              label: 'Status',
              placeholder: 'Selecione o Status',
              type: 'select',
              options: status,
              xl: 4,
              lg: 4,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'initialDate',
              label: 'Data Inicial:',
              type: 'date',
              defaultValue: dateDay,
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
            },
            {
              name: 'finalDate',
              label: 'Data Final:',
              type: 'date',
              defaultValue: dateDay,
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
              xs: 12,
            },
          ]}
          hiddenInputs={[
            {
              name: 'tipo',
              label: 'Tipo',
              placeholder: 'Selecione tipo pedido',
              type: 'select',
              options: typeOptions,
              xl: 4,
              lg: 4,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'ruptura',
              label: 'Ruptura',
              placeholder: 'Selecione ruptura',
              type: 'select',
              options: ruptureOptions,
              xl: 4,
              lg: 4,
              md: 12,
              sm: 12,
              xs: 12,
            },
          ]}
          changeTableModeButton={showTable}
          changeTableModeAction={toggleViewMode}
        />
      </Container>
      {isLoading && <LoadingComponent />}
      {tableViewMode.mode === 'table' && showTable && (
        <OrdersTable
          openSignature={(url: string | null) => handleOpenSignatureModal(url)}
          handleFilter={handleSearchBoxSubmit}
          orderTableRows={ordersState}
          openDetail={handleDetail}
          resetOrder={handleReset}
        />
      )}
      {tableViewMode.mode === 'grid' && showTable && (
        <TableGridMode
          data={ordersState}
          statusList={status}
          openDetail={handleDetail}
        />
      )}

      <PageMode
        isModal
        modalLoading={modalLoading}
        disableSubmit
        open={open}
        handleClose={() => setOpen(!open)}
        title="Detalhes"
      >
        <OrderDetailsTable
          rows={orderDetailsData}
          order={orderDetailInformation}
        />
      </PageMode>
      {showImportModal && (
        <ImportOrdersModal
          handleClose={() => setShowImportModal(false)}
          updateOrderTables={() =>
            handleSearchBoxSubmit({
              status: searchBoxRef.current?.getFieldValue('status'),
              initialDate: searchBoxRef.current?.getFieldValue('initialDate'),
              finalDate: searchBoxRef.current?.getFieldValue('finalDate'),
              pedido: searchBoxRef.current?.getFieldValue('pedido'),
              loja: searchBoxRef.current?.getFieldValue('loja'),
            })
          }
          isOpen={showImportModal}
        />
      )}
      {showOrderSignatureModal && (
        <PageMode
          isModal
          disableSubmit
          title="Assinatura"
          handleClose={handleCloseSignatureModal}
          open={showOrderSignatureModal}
        >
          <div className="signatureImageContainer">
            {modalLoading && <LoadingComponent />}
            <img
              src={signatureUrl}
              alt="Assinatura"
              onLoad={() => setModalLoading(false)}
            />
          </div>
        </PageMode>
      )}
    </>
  );
};

export default Orders;
