import React, { useCallback } from 'react';
import { useToast } from '../../../../hooks/Toast';
import PopUpWindow from '../../../../components/PopUpWindow';
import { handleDeleteStore } from '../api/RegisterUsers.api';
import { IDeleteUsersProps } from '../types/index';

const DeleteUsers: React.FC<IDeleteUsersProps> = ({
  handleClose,
  rechargeUsersData,
  currentValue,
}) => {
  const { Toastsuccess, ToastError } = useToast();
  const hanldleDelete = useCallback(
    codeStore => {
      handleDeleteStore(codeStore)
        .then(() => {
          Toastsuccess({
            message: `O usuário ${currentValue.username} foi excluída com sucesso!`,
          });
          handleClose('delete');
        })
        .catch(err => {
          ToastError({
            message: `${err} não foi possivel concluir a exclusão!`,
          });
        })
        .finally(() => {
          rechargeUsersData({
            search: null,
            status: false,
          });
          handleClose('delete');
        });
    },
    [
      ToastError,
      Toastsuccess,
      currentValue.username,
      handleClose,
      rechargeUsersData,
    ]
  );

  return (
    <PopUpWindow
      title={`Deseja exclui o usuário ${currentValue.username}`}
      handleCloseModal={() => handleClose('delete')}
      handleSubmit={() => hanldleDelete(currentValue.id)}
    />
  );
};
export default DeleteUsers;
