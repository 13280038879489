import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { BsHouse } from 'react-icons/bs';
import { FormHandles } from '@unform/core';

import { Container } from '../../../../styles/PageStyles/Edit';
import { useToast } from '../../../../hooks/Toast';

import Breadcrumb from '../../../../components/Breadcrumb';
import SearchBox from '../../../../components/SearchBox';
import TitleWithButtons from '../../../../components/TitleWithButtons';

import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { currency, measure, weight } from '../../../../utils/masks';

interface CommonItem {
  code: number;
  description: string;
  label?: string;
  value?: number;
}

interface AttributeItem {
  codigo: number;
  descricao: string;
  categoria: string;
  tipo: string;
  codigoPai: number;
  idParceiro: string;
}

const CreateProduct: React.FC = () => {
  const history = useHistory();
  const { ToastError, Toastsuccess } = useToast();
  const { code } = useParams<Record<string, string | undefined>>();
  const inputsBox = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [resetPassword, setResetPassword] = useState(false);

  const [listCategoria, setListCategoria] = useState([] as CommonItem[]);
  const [listMarca, setListMarca] = useState([] as CommonItem[]);

  useEffect(() => {
    if (!Number(code)) {
      history.push('/Product');
    }

    async function getData() {
      try {
        setLoading(true);

        const userResponse = await api.get(`product/${code}`);

        const dataCatetoria = {
          codigo: 0,
          titulo: '',
          tipo: 'Grupo',
        };

        const categoriaResponse = await api.patch('attribute', {
          ...dataCatetoria,
        });

        const modifiedCategoriaList = categoriaResponse.data.map(
          (item: AttributeItem) => ({
            value: item.codigo,
            label: item.categoria,
          })
        );

        setListCategoria([...modifiedCategoriaList] as CommonItem[]);

        const dataMarca = {
          codigo: 0,
          titulo: '',
          tipo: 'Marca',
        };

        const marcaResponse = await api.patch('attribute', {
          ...dataMarca,
        });

        const modifiedMarcaList = marcaResponse.data.map(
          (item: AttributeItem) => ({
            value: item.codigo,
            label: item.descricao,
          })
        );

        setListMarca([...modifiedMarcaList] as CommonItem[]);

        const modifiedSelectedCategoria = {
          value: userResponse.data[0].categoria,
          label: userResponse.data[0].categoriaNome,
        };

        const modifiedSelectedMarca = {
          value: userResponse.data[0].marca,
          label: userResponse.data[0].marcaNome,
        };

        inputsBox.current?.setData({
          ...userResponse.data[0],
          categoria: {
            code: modifiedSelectedCategoria,
          },
          marca: {
            code: modifiedSelectedMarca,
          },
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro!',
        });
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [ToastError, code, history]);

  useEffect(() => {
    if (resetPassword) {
      inputsBox.current?.submitForm();
      setResetPassword(false);
    }
  }, [resetPassword]);

  const handleSave = useCallback(() => {
    inputsBox.current?.submitForm();
  }, []);

  const handleBack = useCallback(() => {
    history.push('/Product');
  }, [history]);

  const submit = useCallback(
    async data => {
      const response = await api.put('product', {
        codigo: code,
        titulo: data.titulo,
        descricao: data.descricao,
        categoria: data.categoria.code,
        marca: data.marca.code,
        modelo: data.modelo,
        peso: data.peso,
        profundidade: data.profundidade,
        largura: data.largura,
        altura: data.altura,
        urlImagem: data.urlImagem,
        preco: data.preco,
      });

      return response;
    },
    [code]
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          produto: Yup.string().required('É necessário preencher o produto'),
          titulo: Yup.string().required('É necessário preencher o titulo'),
          descricao: Yup.string().required(
            'É necessário preencher o descricao'
          ),
          /* categoria: Yup.string().required(
            'É necessário preencher o categoria'
          ),
          marca: Yup.string().required('É necessário preencher o marca'),
          */
          modelo: Yup.string().required('É necessário preencher o modelo'),
          peso: Yup.string().required('É necessário preencher o peso'),
          profundidade: Yup.string().required(
            'É necessário preencher o profundidade'
          ),
          largura: Yup.string().required('É necessário preencher o largura'),
          altura: Yup.string().required('É necessário preencher o altura'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        submit(data);

        Toastsuccess({
          message: 'Produto alterado com sucesso',
        });

        history.push('/Product');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          inputsBox.current?.setErrors(errors);
        }

        ToastError({
          message: 'Ocorreu um erro!',
        });
      }
    },
    [ToastError, Toastsuccess, history, submit]
  );

  return (
    <Container>
      <Breadcrumb title="Produto" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Edição`}
      </Breadcrumb>

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Editar Produto"
              back
              save
              handleSave={handleSave}
              handleBack={handleBack}
            />
            <div className="inputs-box">
              <SearchBox
                searchBoxRef={inputsBox}
                handleSubmit={handleSubmit}
                buttons={false}
                inputs={[
                  {
                    width: 20,
                    label: 'produto:',
                    name: 'produto',
                    placeholder: 'Digite produto',
                    messageErrorOnBlur: 'Digite um produto',
                    readOnly: true,
                  },
                  {
                    width: 80,
                    label: 'Nome:',
                    name: 'titulo',
                    placeholder: 'Digite Nome',
                    messageErrorOnBlur: 'Digite um Nome',
                  },
                  {
                    width: 100,
                    label: 'Descrição:',
                    name: 'descricao',
                    placeholder: 'Digite descricao',
                    messageErrorOnBlur: 'Digite um descricao',
                  },
                  {
                    width: 100,
                    label: 'Categoria:',
                    name: 'categoria.code',
                    type: 'select',
                    placeholder: 'Selecione Categoria',
                    options: listCategoria,
                  },
                  {
                    width: 50,
                    label: 'Marca:',
                    name: 'marca.code',
                    type: 'select',
                    placeholder: 'Selecione Marca',
                    options: listMarca,
                  },
                  {
                    width: 50,
                    label: 'modelo:',
                    name: 'modelo',
                    placeholder: 'Digite modelo',
                    messageErrorOnBlur: 'Digite um modelo',
                  },
                  {
                    width: 25,
                    label: 'peso:',
                    name: 'peso',
                    placeholder: 'Digite peso',
                    messageErrorOnBlur: 'Digite um peso',
                    mask: weight,
                  },
                  {
                    width: 25,
                    label: 'profundidade:',
                    name: 'profundidade',
                    placeholder: 'Digite profundidade',
                    messageErrorOnBlur: 'Digite um profundidade',
                    mask: measure,
                  },
                  {
                    width: 25,
                    label: 'Largura:',
                    name: 'largura',
                    placeholder: 'Digite Largura',
                    messageErrorOnBlur: 'Digite um Largura',
                    mask: measure,
                  },
                  {
                    width: 25,
                    label: 'altura:',
                    name: 'altura',
                    placeholder: 'Digite altura',
                    messageErrorOnBlur: 'Digite um altura',
                    mask: measure,
                  },
                  {
                    width: 20,
                    label: 'Preço:',
                    name: 'preco',
                    placeholder: 'Digite preço',
                    messageErrorOnBlur: 'Digite um preço',
                    mask: currency,
                  },
                  {
                    width: 20,
                    label: 'Estoque:',
                    name: 'estoque',
                    placeholder: 'Digite estoque',
                    messageErrorOnBlur: 'Digite um estoque',
                    mask: weight,
                  },
                  {
                    width: 100,
                    label: 'Url Imagem:',
                    name: 'urlImagem',
                    placeholder: 'Digite urlImagem',
                    messageErrorOnBlur: 'Digite um urlImagem',
                  },
                  {
                    width: 33,
                    label: 'ativo:',
                    name: 'ativo',
                    placeholder: 'Digite ativo',
                    type: 'switch-button',
                    messageErrorOnBlur: 'Digite um ativo',
                  },
                  {
                    width: 33,
                    readOnly: true,
                    label: 'Integrado ? :',
                    name: 'status',
                    type: 'switch-button',
                    placeholder: 'Digite status',
                    messageErrorOnBlur: 'Digite um status',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateProduct;
