import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { BsHouse } from 'react-icons/bs';
import { FormHandles } from '@unform/core';

import { Container } from '../../../../styles/PageStyles/Edit';
import { useToast } from '../../../../hooks/Toast';

import Breadcrumb from '../../../../components/Breadcrumb';
import SearchBox from '../../../../components/SearchBox';
import TitleWithButtons from '../../../../components/TitleWithButtons';

import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

const CreateJob: React.FC = () => {
  const history = useHistory();
  const { ToastError, Toastsuccess } = useToast();
  const { code } = useParams<Record<string, string | undefined>>();
  const inputsBox = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    if (!Number(code)) {
      history.push('/Job');
    }

    async function getData() {
      try {
        setLoading(true);

        const userResponse = await api.get(`Jobs/${code}`);

        inputsBox.current?.setData({
          ...userResponse.data,
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro!',
        });
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [ToastError, code, history]);

  useEffect(() => {
    if (resetPassword) {
      inputsBox.current?.submitForm();
      setResetPassword(false);
    }
  }, [resetPassword]);

  const handleSave = useCallback(() => {
    inputsBox.current?.submitForm();
  }, []);

  const handleBack = useCallback(() => {
    history.push('/Job');
  }, [history]);

  const submit = useCallback(
    async data => {
      const response = await api.put('Jobs', {
        codidt: code,
        ...data,
      });

      return response;
    },
    [code]
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          nomeJob: Yup.string().required('É necessário preencher o nomeJob'),
          descJob: Yup.string().required('É necessário preencher o descJob'),
          cronJob: Yup.string().required('É necessário preencher o cronJob'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        submit(data);

        Toastsuccess({
          message: 'Job editado com sucesso',
        });

        history.push('/Job');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          inputsBox.current?.setErrors(errors);
        }

        ToastError({
          message: 'Ocorreu um erro!',
        });
      }
    },
    [ToastError, Toastsuccess, history, submit]
  );

  return (
    <Container>
      <Breadcrumb title="Job" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Edição`}
      </Breadcrumb>

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Editar Job"
              back
              save
              handleSave={handleSave}
              handleBack={handleBack}
            />
            <div className="inputs-box">
              <SearchBox
                searchBoxRef={inputsBox}
                handleSubmit={handleSubmit}
                buttons={false}
                inputs={[
                  {
                    width: 50,
                    label: 'Nome:',
                    name: 'nomeJob',
                    placeholder: 'Digite Nome',
                    messageErrorOnBlur: 'Digite um Nome',
                  },
                  {
                    width: 50,
                    label: 'Cron:',
                    name: 'cronJob',
                    placeholder: 'Digite Cron',
                    messageErrorOnBlur: 'Digite um Cron',
                  },
                  {
                    width: 100,
                    label: 'Descrição:',
                    name: 'descJob',
                    placeholder: 'Digite Descrição',
                    messageErrorOnBlur: 'Digite um Descrição',
                  },
                  {
                    width: 20,
                    label: 'ativo:',
                    name: 'ativo',
                    placeholder: 'Digite ativo',
                    type: 'switch-button',
                    messageErrorOnBlur: 'Digite um ativo',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateJob;
