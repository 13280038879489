import React, { useCallback, useState } from 'react';
import { Container, TableContainer } from '../styles/styles';
import deleteIcon from '../../../../assets/svg/deleteActionsIcon.svg';
import editIcon from '../../../../assets/svg/editIcon.svg';
import TagStatus from '../../../../components/TagStatus';
import { Table } from '../../../../components/NewTable';
import EditStore from '../EditStore';
import DeleteStore from '../DeleteStore';
import { IStoreTableProps, IStoreData } from '../types';

const StoreTable: React.FC<IStoreTableProps> = ({
  storeData,
  typesStore,
  rechargeStoreData,
}) => {
  const [isDeleteStoreOpen, setIsDeleteStoreModalOpen] = useState(false);
  const [isEditStoreOpen, setIsEditStoreModalOpen] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<IStoreData>({} as IStoreData);

  const handleStatus = useCallback((res: any) => {
    return res === true ? 'Reduzido' : 'Normal';
  }, []);

  const handleCloseAnyModal = useCallback((modalType: 'edit' | 'delete') => {
    if (modalType === 'delete') {
      setIsDeleteStoreModalOpen(false);
    } else {
      setIsEditStoreModalOpen(false);
    }
  }, []);

  return (
    <>
      <Container>
        <TableContainer>
          <Table<IStoreData>
            columns={[
              {
                title: 'Id',
                type: 'stringCenter',
                orderable: true,
                props: ['id'],
                cssProps: {
                  width: '1%',
                },
                renderItem: row => (
                  <div className="orderColumn">
                    <p style={{ marginLeft: '10px' }}>{row.id}</p>
                  </div>
                ),
              },
              {
                title: 'Codigo Loja ERP',
                type: 'stringCenter',
                orderable: true,
                props: ['code'],
                cssProps: {
                  width: '1%',
                },
              },
              {
                title: 'Nome Loja',
                type: 'string',
                orderable: true,
                props: ['name'],
                cssProps: {
                  width: '1%',
                },
                cssTitle: {
                  width: '1%',
                },
              },
              {
                title: 'ID Feed AJ',
                type: 'string',
                orderable: true,
                props: ['feed'],
                cssProps: {
                  width: '15%',
                },
                cssTitle: {
                  width: '1%',
                },
              },
              {
                title: 'Tipo Fluxo',
                type: 'stringCenter',
                orderable: true,
                props: ['is_workflow'],
                cssProps: {
                  width: '1%',
                  marginRight: '10%',
                },
                renderItem: row => (
                  <TagStatus status={handleStatus(row.is_workflow)} />
                ),
              },
              {
                title: 'Ações',
                type: undefined,
                props: [''],
                cssProps: {
                  width: '2%',
                },
                renderItem: row => (
                  <div className="row-actions-button-detail">
                    <button
                      type="button"
                      className="action"
                      onClick={() => {
                        setIsDeleteStoreModalOpen(true);
                        setCurrentData(row);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        style={{ width: '20px' }}
                        alt="delete"
                      />
                      <p className="hover-item">Excluir</p>
                    </button>
                    <button
                      type="button"
                      className="action"
                      onClick={() => {
                        setCurrentData(row);
                        setIsEditStoreModalOpen(true);
                      }}
                    >
                      <img
                        src={editIcon}
                        alt="edit"
                        style={{ width: '20px' }}
                      />
                      <p className="hover-item">Editar</p>
                    </button>
                  </div>
                ),
              },
            ]}
            defaultNumberOfRows={10}
            exportList
            rows={[
              ...storeData.map(item => ({
                ...item,
              })),
            ]}
          />
        </TableContainer>
        <EditStore
          isOpen={isEditStoreOpen}
          rechargeStoreData={rechargeStoreData}
          handleClose={handleCloseAnyModal}
          typesStore={typesStore}
          currentValue={currentData}
        />
        {isDeleteStoreOpen && (
          <DeleteStore
            currentValue={currentData}
            rechargeStoreData={rechargeStoreData}
            handleClose={handleCloseAnyModal}
          />
        )}
      </Container>
    </>
  );
};
export default StoreTable;
