import styled from 'styled-components';

export const Container = styled.div`
  .tableWrapper {
    .tableWrapperBox {
      max-height: 250px;
      overflow: auto;
    }
  }

  .detailsItem {
    img {
      margin-right: 5px;
    }
  }

  .table-box {
    .orderImage {
      display: flex;
      justify-content: center;
      img {
        max-width: 40px;
        max-height: 35px;
      }
    }
  }

  .wrapper {
    padding: 0rem 1rem 3.5rem;
    .details {
      display: flex;
      flex-direction: column;
      background: rgba(245, 248, 250, 0.4);
      border: 1px dashed #e4e6ef;
      border-radius: 6px;
      margin-bottom: 1rem;

      .detailsContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .detailsItemsContainer {
          display: flex;
          justify-content: space-between;
          margin: 12px 0 20px 15px;
          gap: 3rem;
          flex-wrap: wrap;
          padding: 0 10px;

          #redValue {
            p:last-of-type {
              color: #f64e60;
            }
          }

          .totalTimeResume {
            .detailsItem {
              p:last-of-type {
                color: #46be7d;
                font-weight: 600;
              }
            }
          }

          .detailsItem {
            :last-of-type {
              display: flex;
              flex-direction: column;
            }
            p {
              display: flex;
              align-items: center;
              :first-of-type {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 12px;
                color: #7e8299;
              }
              :last-of-type {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 18px;
                color: #3f4254;
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }

  .detailHeaderDivider {
    margin: 0.8rem 0 1.5rem;
    p {
      margin-left: 2rem;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      /* or 117% */

      display: flex;
      align-items: center;

      color: #040404;
    }
  }
`;
