import styled from 'styled-components';

interface Props {
  color?: string;
  fontColor?: string;
}

export const Grafico = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  min-width: 0;
  word-wrap: break-word;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-left: 30px;
  justify-content: center;

  img {
    max-width: 100%;
    width: 1438px;
    height: 611px;
    transition: fill 0.3s ease;
    fill: #fff;
    svg {
      fill: #fff;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  min-width: 0;
  word-wrap: break-word;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

export const CardBody = styled.div<Props>`
  flex: 1 1 auto;
  padding: 2rem 2.25rem;
  background-color: ${props => props.color || 'inherit'};
  position: relative;
  margin-left: 30px;
  margin-top: 10px;
  border-radius: 12px;
  width: 338.19px;
  height: 180px;

  .p {
    font-size: 1.5rem;
    color: #b5b5c3;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .boxValor {
    font-size: 1.2rem;
    color: ${props => props.fontColor};
    font-weight: 600;
  }

  .boxTexto {
    font-size: 0.7rem;
    color: ${props => props.fontColor};
    font-weight: 500;
  }

  .icon {
    .svg-icon-success {
      fill: #ffc700;
      background: #ffc700;
    }

    img {
      max-width: 100%;
      width: 40px;
      height: 40px;
      transition: fill 0.3s ease;
      fill: #fff;
      svg {
        fill: #fff;
      }
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;

  .h1 {
    font-size: 10rem;
    color: #3f4254;
    margin-top: 3.75rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  .p {
    font-size: 1.5rem;
    color: #b5b5c3;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;
