export interface SelectItem {
  codigo?: number | string | boolean;
  description?: string;
  descricao?: string;
  label?: string;
  value?: number | string | boolean;
  uf?: string;
  id?: number;
  id_branch?: string;
  branch_code?: number;
  nome?: string;
  name?: string;
  code?: number;
  codigoUsuario?: number | string | boolean;
  codigoTipo?: number | string;
  periodo?: number | string;
  codigoFilial?: number | string;
  codigoFilialERP?: number | string;
  tipoPedido?: string;
  subsidiaryCode?: string;
  statusCode?: string;
}

export const transformToSelect = (array: any) => {
  return array.map((item: SelectItem) => ({
    code:
      item.code ||
      item.id ||
      item.codigo ||
      item.uf ||
      item.codigoUsuario ||
      item.codigoTipo ||
      item.codigoFilial ||
      item.periodo,
    description:
      item.descricao ||
      item.nome ||
      item.periodo ||
      item.codigoFilialERP ||
      item.description,
    label:
      item.name ||
      item.descricao ||
      item.nome ||
      item.periodo ||
      item.codigoFilialERP ||
      item.description,
    value:
      item.branch_code ||
      item.code ||
      item.id_branch ||
      item.codigo ||
      item.id ||
      item.uf ||
      item.codigoUsuario ||
      item.codigoTipo ||
      item.codigoFilial ||
      item.periodo ||
      item.tipoPedido ||
      item.subsidiaryCode ||
      item.statusCode,
  }));
};
