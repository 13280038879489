import React, { useEffect, useState, useCallback } from 'react';
import { BsHouse } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import Breadcrumb from '../../../components/Breadcrumb';
import SearchBoxComponent from '../../../components/SearchBox';
import PopUpWindow from '../../../components/PopUpWindow';
import Table from '../../../components/Table';
import LoadingComponent from '../../../components/LoadingComponent';
import TitleWithButtons from '../../../components/TitleWithButtons';
import { useToast } from '../../../hooks/Toast';

import api from '../../../services/api';

import {
  ButtonLink,
  Container as PageListStyle,
} from '../../../styles/PageStyles/List';

interface ParametroItem {
  codidt: number;
  parametro: string;
  valorAtual: string;
  descParametro: string;
  dtInclusao: Date;
  dtAlteracao: Date;
  ativo: boolean;
}

const Parametros: React.FC = () => {
  const [parametros, setParametros] = useState([] as ParametroItem[]);
  const [loading, setLoading] = useState(true);
  const [deleteParametro, setDeleteParametro] = useState(NaN as number);

  const { ToastError, Toastsuccess } = useToast();
  const history = useHistory();

  useEffect(() => {
    async function getParametros() {
      try {
        setLoading(true);

        const data = {};

        const parametroResponse = await api.get('Parametros', {
          ...data,
        });

        setParametros([...parametroResponse.data]);
      } catch (err) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    }

    getParametros();
  }, [ToastError]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        const parametroResponse = await api.get('Parametros', {
          ...data,
        });

        setParametros([...parametroResponse.data]);

        Toastsuccess({
          message: 'Pesquisa feita com sucesso!',
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    },
    [Toastsuccess, ToastError]
  );

  const handleDeleteParametro = useCallback(async () => {
    try {
      if (!deleteParametro) {
        throw new Error('Parametro  inexistente');
      }

      await api.delete(`Parametros/${deleteParametro}`);

      Toastsuccess({
        message: `Parametro ${deleteParametro} deletado com sucesso!`,
      });

      setDeleteParametro(NaN);
    } catch (error) {
      ToastError({
        message: 'Ocorreu um erro',
      });
    }
  }, [Toastsuccess, ToastError, deleteParametro]);

  const deleteThisParametro = useCallback(code => {
    setDeleteParametro(code);
  }, []);

  const editParametro = useCallback(
    code => {
      history.push(`/Parametro/edit/${code}`);
    },
    [history]
  );

  return (
    <PageListStyle>
      <Breadcrumb
        title="Parametros"
        icon={<BsHouse size={16} color="#c0c0c6" />}
      >
        {`Administração > Lista`}
      </Breadcrumb>

      {!!deleteParametro && (
        <PopUpWindow
          title="Deseja deletar?"
          handleSubmit={handleDeleteParametro}
          handleCancel={() => setDeleteParametro(NaN)}
        >
          Você tem certeza que deseja deletar?
        </PopUpWindow>
      )}

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Lista de Pârametro"
              filterTitle="Opções de Filtro"
              filter
              filterContent={() => (
                <SearchBoxComponent
                  handleSubmit={handleSubmit}
                  justifycontent="flex-start"
                  inputs={[
                    {
                      width: 50,
                      label: 'Pârametro:',
                      name: 'parametro',
                      placeholder: 'Digite o Pârametro',
                      messageErrorOnBlur: 'Digite um Pârametro',
                    },
                    {
                      width: 50,
                      label: 'Valor:',
                      name: 'valorAtual',
                      placeholder: 'Digite o Valor',
                      messageErrorOnBlur: 'Digite um Valor',
                    },
                  ]}
                />
              )}
            >
              <ButtonLink to="/Parametro/create" type="button">
                Novo Pârametro
              </ButtonLink>
            </TitleWithButtons>

            {loading ? (
              <LoadingComponent />
            ) : (
              <div className="table-box">
                <Table<ParametroItem>
                  rows={[
                    ...parametros.map(item => ({
                      ...item,
                    })),
                  ]}
                  columns={[
                    {
                      title: 'Código',
                      orderable: true,
                      type: 'number',
                      props: ['codidt'],
                      cssProps: {
                        width: '5%',
                      },
                    },
                    {
                      title: 'Pârametro',
                      orderable: true,
                      type: 'string',
                      props: ['parametro'],
                      cssProps: {
                        width: '20%',
                      },
                    },
                    {
                      title: 'Valor',
                      orderable: true,
                      type: 'string',
                      props: ['valorAtual'],
                      cssProps: {
                        width: '20%',
                      },
                    },
                    {
                      title: 'Descrição',
                      orderable: true,
                      type: 'string',
                      props: ['descParametro'],
                      cssProps: {
                        width: '40%',
                      },
                    },
                    {
                      title: 'Ativo',
                      orderable: true,
                      type: 'boolean',
                      props: ['ativo'],
                      cssProps: {
                        width: '5%',
                      },
                    },
                  ]}
                  paginationOptions={[
                    {
                      value: 30,
                      label: 30,
                    },
                    {
                      value: 50,
                      label: 50,
                    },
                    {
                      value: 100,
                      label: 100,
                    },
                  ]}
                  defaultSort="codidt desc"
                  onDeleteRow={row => {
                    deleteThisParametro(row.codidt);
                  }}
                  onEditRow={row => {
                    editParametro(row.codidt);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageListStyle>
  );
};

export default Parametros;
