import React, { useEffect, useState, useCallback } from 'react';
import { BsHouse } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import Breadcrumb from '../../../components/Breadcrumb';
import SearchBoxComponent from '../../../components/SearchBox';
import PopUpWindow from '../../../components/PopUpWindow';
import Table from '../../../components/Table';
import LoadingComponent from '../../../components/LoadingComponent';
import TitleWithButtons from '../../../components/TitleWithButtons';
import { useToast } from '../../../hooks/Toast';

import api from '../../../services/api';

import {
  ButtonLink,
  Container as PageListStyle,
} from '../../../styles/PageStyles/List';

interface JobItem {
  codidt: number;
  nomeJob: string;
  descJob: string;
  cronJob: string;
  dtInclusao: Date;
  dtAlteracao: Date;
  ativo: boolean;
}

const Jobs: React.FC = () => {
  const [jobs, setJobs] = useState([] as JobItem[]);
  const [loading, setLoading] = useState(true);
  const [deleteJob, setDeleteJob] = useState(NaN as number);

  const { ToastError, Toastsuccess } = useToast();
  const history = useHistory();

  useEffect(() => {
    async function getJobs() {
      try {
        setLoading(true);

        const data = {};

        const jobResponse = await api.get('Jobs', {
          ...data,
        });

        setJobs([...jobResponse.data]);
      } catch (err) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    }

    getJobs();
  }, [ToastError]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        const jobResponse = await api.get('Jobs', {
          ...data,
        });

        setJobs([...jobResponse.data]);

        Toastsuccess({
          message: 'Pesquisa feita com sucesso!',
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    },
    [Toastsuccess, ToastError]
  );

  const handleDeleteJob = useCallback(async () => {
    try {
      if (!deleteJob) {
        throw new Error('Job  inexistente');
      }

      await api.delete(`Jobs/${deleteJob}`);

      Toastsuccess({
        message: `Job ${deleteJob} deletado com sucesso!`,
      });

      setDeleteJob(NaN);
    } catch (error) {
      ToastError({
        message: 'Ocorreu um erro',
      });
    }
  }, [Toastsuccess, ToastError, deleteJob]);

  const deleteThisJob = useCallback(code => {
    setDeleteJob(code);
  }, []);

  const editJob = useCallback(
    code => {
      history.push(`/Job/edit/${code}`);
    },
    [history]
  );

  return (
    <PageListStyle>
      <Breadcrumb title="Jobs" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Lista`}
      </Breadcrumb>

      {!!deleteJob && (
        <PopUpWindow
          title="Deseja deletar?"
          handleSubmit={handleDeleteJob}
          handleCancel={() => setDeleteJob(NaN)}
        >
          Você tem certeza que deseja deletar?
        </PopUpWindow>
      )}

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Lista de Jobs"
              filterTitle="Opções de Filtro"
              filter
              filterContent={() => (
                <SearchBoxComponent
                  handleSubmit={handleSubmit}
                  justifycontent="flex-start"
                  inputs={[
                    {
                      width: 50,
                      label: 'nomeJob:',
                      name: 'nomeJob',
                      placeholder: 'Digite o nomeJob',
                      messageErrorOnBlur: 'Digite um nomeJob',
                    },
                    {
                      width: 50,
                      label: 'descJob:',
                      name: 'descJob',
                      placeholder: 'Digite o descJob',
                      messageErrorOnBlur: 'Digite um descJob',
                    },
                  ]}
                />
              )}
            >
              <ButtonLink to="/Job/create" type="button">
                Novo Job
              </ButtonLink>
            </TitleWithButtons>

            {loading ? (
              <LoadingComponent />
            ) : (
              <div className="table-box">
                <Table<JobItem>
                  rows={[
                    ...jobs.map(item => ({
                      ...item,
                    })),
                  ]}
                  columns={[
                    {
                      title: 'Codigo',
                      orderable: true,
                      type: 'number',
                      props: ['codidt'],
                      cssProps: {
                        width: '5%',
                      },
                    },
                    {
                      title: 'Nome',
                      orderable: true,
                      type: 'string',
                      props: ['nomeJob'],
                      cssProps: {
                        width: '20%',
                      },
                    },
                    {
                      title: 'Cron',
                      orderable: true,
                      type: 'string',
                      props: ['cronJob'],
                      cssProps: {
                        width: '20%',
                      },
                    },
                    {
                      title: 'Descrição',
                      orderable: true,
                      type: 'string',
                      props: ['descJob'],
                      cssProps: {
                        width: '40%',
                      },
                    },

                    {
                      title: 'Ativo',
                      orderable: true,
                      type: 'boolean',
                      props: ['ativo'],
                      cssProps: {
                        width: '5%',
                      },
                    },
                  ]}
                  paginationOptions={[
                    {
                      value: 30,
                      label: 30,
                    },
                    {
                      value: 50,
                      label: 50,
                    },
                    {
                      value: 100,
                      label: 100,
                    },
                  ]}
                  defaultSort="codidt desc"
                  onDeleteRow={row => {
                    deleteThisJob(row.codidt);
                  }}
                  onEditRow={row => {
                    editJob(row.codidt);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageListStyle>
  );
};

export default Jobs;
