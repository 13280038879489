import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState } from 'react';
import PageMode from '../../../../components/PageMode';
import { useToast } from '../../../../hooks/Toast';
import { handleEditUserPassword } from '../api/RegisterUsers.api';
import { IEditUsersProps } from '../types';

const EditUserPassword: React.FC<IEditUsersProps> = ({
  isOpen,
  handleClose,
  currentValue,
  rechargeUsersData,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const editInputsRef = useRef<FormHandles>(null);
  const { Toastsuccess, ToastError } = useToast();

  const handleEdit = useCallback(
    (data: any) => {
      setIsModalLoading(true);

      const newPassword = editInputsRef.current?.getFieldValue('password');
      const confirmedPassword =
        editInputsRef.current?.getFieldValue('confirmedPassword');

      if (newPassword === confirmedPassword) {
        handleEditUserPassword(currentValue.id, newPassword)
          .then(() => {
            Toastsuccess({
              message: `Senha alterada com sucesso!`,
            });
          })
          .catch(err => {
            ToastError({ message: `Ops falha ao alterar senha ${err}!` });
            handleClose('edit');
          })
          .finally(() => {
            rechargeUsersData({
              search: null,
              status: false,
            });
            handleClose('password');
            setIsModalLoading(false);
          });
      } else {
        setIsModalLoading(false);
        ToastError({ message: `Os campos de senha devem ser iguais!` });
      }
    },
    [ToastError, Toastsuccess, currentValue.id, handleClose, rechargeUsersData]
  );

  return (
    <PageMode
      isModal
      open={isOpen}
      modalLoading={isModalLoading}
      handleClose={() => handleClose('password')}
      modalRefObject={editInputsRef}
      submitButtonTitle="Salvar"
      submitButtonIcon
      title={`Editar Usuário ${currentValue.username}`}
      handleSubmit={data => handleEdit(data)}
      inputs={[
        {
          name: 'password',
          label: 'Nova senha',
          placeholder: 'Digite a nova senha',
          type: 'text',
          isRequired: true,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 6,
          sm: 6,
        },
        {
          name: 'confirmedPassword',
          label: 'Confirme a senha',
          placeholder: 'Confirme a nova senha',
          type: 'text',
          isRequired: true,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 6,
          sm: 6,
        },
      ]}
    />
  );
};
export default EditUserPassword;
