import React, { useEffect, useState, useCallback } from 'react';
import { BsHouse } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import Breadcrumb from '../../../components/Breadcrumb';
import SearchBoxComponent from '../../../components/SearchBox';
import PopUpWindow from '../../../components/PopUpWindow';
import Table from '../../../components/Table';
import LoadingComponent from '../../../components/LoadingComponent';
import TitleWithButtons from '../../../components/TitleWithButtons';
import { useToast } from '../../../hooks/Toast';

import api from '../../../services/api';

import {
  ButtonLink,
  Container as PageListStyle,
} from '../../../styles/PageStyles/List';

interface AttributeItem {
  codigo: number;
  descricao: string;
  categoria: string;
  tipo: string;
  codigoPai: number;
  idParceiro: string;
  id: number;
  branchId: number;
  creationDate: string;
  item: number;
  shippingCompany: string;
  shippingCompanyCode: string;
  status: string;
}

const Attributes: React.FC = () => {
  const [attributes, setAttributes] = useState([] as AttributeItem[]);
  const [loading, setLoading] = useState(true);
  const [deleteAttribute, setDeleteAttribute] = useState(NaN as number);

  const { ToastError, Toastsuccess } = useToast();
  const history = useHistory();

  useEffect(() => {
    async function getAttributes() {
      try {
        setLoading(true);

        const data = {
          codigo: 0,
          titulo: '',
          tipo: '',
        };

        const attributeResponse = await api.get('/boarding/search');

        setAttributes([...attributeResponse.data.boardings]);
      } catch (err) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    }

    getAttributes();
  }, [ToastError]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        const dataFiltros = {
          codigo: 0,
          titulo: data.descricao,
          tipo: data.tipo,
        };

        const attributeResponse = await api.patch('attribute', {
          ...dataFiltros,
        });

        setAttributes([...attributeResponse.data]);

        Toastsuccess({
          message: 'Pesquisa feita com sucesso!',
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    },
    [Toastsuccess, ToastError]
  );

  const handleDeleteAttribute = useCallback(async () => {
    try {
      if (!deleteAttribute) {
        throw new Error('Attribute  inexistente');
      }

      await api.delete(`Atributos/${deleteAttribute}`);

      Toastsuccess({
        message: `Attribute ${deleteAttribute} deletado com sucesso!`,
      });

      setDeleteAttribute(NaN);
    } catch (error) {
      ToastError({
        message: 'Ocorreu um erro',
      });
    }
  }, [Toastsuccess, ToastError, deleteAttribute]);

  const deleteThisAttribute = useCallback(code => {
    setDeleteAttribute(code);
  }, []);

  const editAttribute = useCallback(
    code => {
      history.push(`/Attribute/edit/${code}`);
    },
    [history]
  );

  return (
    <PageListStyle>
      <Breadcrumb
        title="Expedição"
        icon={<BsHouse size={16} color="#c0c0c6" />}
      >
        {`Administração > Lista`}
      </Breadcrumb>

      {!!deleteAttribute && (
        <PopUpWindow
          title="Deseja deletar?"
          handleSubmit={handleDeleteAttribute}
          handleCancel={() => setDeleteAttribute(NaN)}
        >
          Você tem certeza que deseja deletar?
        </PopUpWindow>
      )}

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Consulta de Expedição"
              filterTitle="Opções de Filtro"
              filter
              filterContent={() => (
                <SearchBoxComponent
                  handleSubmit={handleSubmit}
                  justifycontent="flex-start"
                  inputs={[
                    {
                      width: 50,
                      label: 'descricao:',
                      name: 'descricao',
                      placeholder: 'Digite o descricao',
                      messageErrorOnBlur: 'Digite um descricao',
                    },
                    {
                      width: 50,
                      label: 'tipo:',
                      name: 'tipo',
                      placeholder: 'Digite o tipo',
                      messageErrorOnBlur: 'Digite um tipo',
                    },
                  ]}
                />
              )}
            />

            {loading ? (
              <LoadingComponent />
            ) : (
              <div className="table-box">
                <Table<AttributeItem>
                  rows={[
                    ...attributes.map(item => ({
                      ...item,
                    })),
                  ]}
                  columns={[
                    {
                      title: 'ID',
                      orderable: true,
                      type: 'number',
                      props: ['id'],
                      cssProps: {
                        width: '5%',
                      },
                    },
                    {
                      title: 'Loja',
                      orderable: true,
                      type: 'string',
                      props: ['branchId'],
                      cssProps: {
                        width: '10%',
                      },
                    },
                    {
                      title: 'Cod.Transportadora',
                      orderable: true,
                      type: 'string',
                      props: ['shippingCompanyCode'],
                      cssProps: {
                        width: '10%',
                      },
                    },

                    {
                      title: 'Transportadora',
                      orderable: true,
                      type: 'string',
                      props: ['shippingCompany'],
                      cssProps: {
                        width: '20%',
                      },
                    },
                    {
                      title: 'Status',
                      orderable: true,
                      type: 'string',
                      props: ['status'],
                      cssProps: {
                        width: '10%',
                      },
                    },
                    {
                      title: 'Data de Expedição',
                      orderable: true,
                      type: 'datetime',
                      props: ['creationDate'],
                      cssProps: {
                        width: '15%',
                      },
                    },
                  ]}
                  paginationOptions={[
                    {
                      value: 30,
                      label: 30,
                    },
                    {
                      value: 50,
                      label: 50,
                    },
                    {
                      value: 100,
                      label: 100,
                    },
                  ]}
                  exportOption
                  defaultSort="descricao asc"
                  onDeleteRow={row => {
                    deleteThisAttribute(row.codigo);
                  }}
                  onEditRow={row => {
                    editAttribute(row.codigo);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageListStyle>
  );
};

export default Attributes;
