import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import PageMode from '../../../../components/PageMode';
import { useToast } from '../../../../hooks/Toast';
import { handleEditUserData } from '../api/RegisterUsers.api';
import { IEditUsersProps } from '../types';

const EditUsers: React.FC<IEditUsersProps> = ({
  isOpen,
  handleClose,
  currentValue,
  rechargeUsersData,
  store,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const editInputsRef = useRef<FormHandles>(null);
  const { Toastsuccess, ToastError } = useToast();

  const hanldleCreate = useCallback(
    (data: any) => {
      setIsModalLoading(true);
      handleEditUserData(currentValue.id, data)
        .then(() => {
          Toastsuccess({
            message: `Usuário editado com sucesso!`,
          });
        })
        .catch(err => {
          ToastError({ message: `Ops falha ao editar usuário ${err}!` });
          handleClose('edit');
        })
        .finally(() => {
          rechargeUsersData({
            search: null,
            status: false,
          });
          handleClose('edit');
          setIsModalLoading(false);
        });
    },
    [ToastError, Toastsuccess, currentValue.id, handleClose, rechargeUsersData]
  );

  const handleValueCurrent = useCallback(() => {
    editInputsRef.current?.setFieldValue('status', currentValue.status);
    if (currentValue.branch_code) {
      editInputsRef.current?.setFieldValue('branch_id', {
        label: currentValue.branch_name,
        value: currentValue.branch_code,
      });
    }
  }, [currentValue.branch_code, currentValue.branch_name, currentValue.status]);

  useEffect(() => {
    handleValueCurrent();
  }, [handleValueCurrent, isOpen]);

  return (
    <PageMode
      isModal
      open={isOpen}
      modalLoading={isModalLoading}
      handleClose={() => handleClose('edit')}
      modalRefObject={editInputsRef}
      submitButtonTitle="Salvar"
      submitButtonIcon
      title={`Editar Usuário ${currentValue.username}`}
      handleSubmit={data => hanldleCreate(data)}
      inputs={[
        {
          name: 'branch_id',
          label: 'Loja',
          placeholder: 'Selecione a loja',
          type: 'select',
          options: store,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 6,
          sm: 6,
        },
        {
          name: 'status',
          label: 'Está ativo ?',
          type: 'switch',
          isDisabled: isModalLoading,
          defaultChecked: true,
        },
      ]}
    />
  );
};
export default EditUsers;
