export function formatDateWithOffset(
  hoursOffset: number,
  minus?: boolean,
  date?: Date
) {
  let adjustedDate;
  if (date) {
    adjustedDate = new Date(date);
  } else {
    adjustedDate = new Date();
  }

  if (minus) {
    adjustedDate.setHours(adjustedDate.getHours() - hoursOffset);
  } else {
    adjustedDate.setHours(adjustedDate.getHours() + hoursOffset);
  }

  return adjustedDate.toLocaleString();
}
