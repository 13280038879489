import React, { useCallback } from 'react';
import {
  ContentTitle,
  TitleContent,
  TitleInfo,
  WindowContainer,
} from './styles';

interface LogItem {
  codidt: number;
  name: string;
  type: string;
  value: string;
  source: string;
  timestamp: Date;
  level: string;
  message: string;
  exception: string;
  timestamputcInsert: Date;
}

interface PopUpWindowProductProps {
  title?: string;
  detailLog: LogItem;
  handleSubmit?(): void;
  handleCancel?(): void;
}

const DetailLog: React.FC<PopUpWindowProductProps> = ({
  title,
  detailLog,
  handleCancel,
}) => {
  const handleClickCancel = useCallback(() => {
    if (handleCancel) {
      handleCancel();
    }
  }, [handleCancel]);

  return (
    <WindowContainer>
      <div className="popup">
        <div className="popupHeader">
          {!!title && <h5>{title}</h5>}
          <div className="contentRightGrid">
            <button type="button" className="close">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                color="#ccc"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: 'rgba(204, 204, 204)' }}
                onClick={handleClickCancel}
              >
                <path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="informations-line">
          <ContentTitle>
            <TitleInfo>Código:</TitleInfo>
            <span className="red">{detailLog.codidt}</span>
          </ContentTitle>
          <ContentTitle>
            <TitleInfo>Data Log:</TitleInfo>
            <span>{detailLog.timestamp}</span>
          </ContentTitle>
          <ContentTitle>
            <TitleInfo>Status:</TitleInfo>
            <span className="red">{detailLog.type}</span>
          </ContentTitle>
          <ContentTitle>
            <TitleInfo>Nome Job:</TitleInfo>
            <span>{detailLog.name}</span>
          </ContentTitle>
        </div>
        <div className="informations-line">
          <TitleContent>Função:</TitleContent>
          <p>{detailLog.value}</p>
        </div>
        <div className="informations-line">
          <TitleContent>Descrição:</TitleContent>
          <p>{detailLog.source}</p>
        </div>
        <div className="informations-line">
          <TitleContent>Exception:</TitleContent>
          <p>{detailLog.exception}</p>
        </div>
        <div className="informations-line">
          <TitleContent>Json:</TitleContent>
          <p>{detailLog.message}</p>
        </div>
        <div className="popupFooter">
          <div className="buttons">
            <button
              type="button"
              className="cancel"
              onClick={handleClickCancel}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </WindowContainer>
  );
};

export default DetailLog;
