import api from '../../../services/api';
import { transformToSelect } from '../../../utils/toSelect';
import { IOrder, IOrderInputs } from '../types';

export const searchBoxRequest = async (data?: IOrderInputs) => {
  console.log(data);

  const params = {
    page: 1,
    perPage: 2000,
    orderId: data?.pedido || null,
    orderNumber: data?.orderNumber || null,
    branchId: data?.loja || null,
    creationDateStart: data?.initialDate || null,
    creationDateEnd: data?.finalDate || null,
    status: data?.status || null,
    isRupture: data?.ruptura || null,
    typeOrder: data?.tipo || null,
  };

  return api.get<IOrder[]>('/orders/search', {
    params,
  });
};

export const getDetailData = (branchId: number, orderId: number) =>
  api.get(`/items/${branchId}/${orderId}`);

export const postResetOrdemData = (branchId: number, orderId: number) =>
  api.put(`/orders/reset/${orderId}`);

export const getSelectOptions = async () => {
  const [statusRes, lojaRes] = await Promise.all([
    api.get('/status'),
    api.get('/branch'),
  ]);

  const options = lojaRes.data.map((res: any) => {
    const store = {
      description: res.name,
      branch_code: res.code,
      id: res.id,
    };
    return store;
  });

  const formatedStatus = transformToSelect(statusRes.data);
  const formatedLojas = transformToSelect(options);

  return [formatedStatus, formatedLojas];
};

export const uploadFile = (file: any) =>
  api.post('/orders/v2/fileOrder', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
