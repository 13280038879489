import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { BsHouse } from 'react-icons/bs';
import { FormHandles } from '@unform/core';

import getValidationErrors from '../../../../utils/getValidationErrors';
import { useToast } from '../../../../hooks/Toast';

import Breadcrumb from '../../../../components/Breadcrumb';
import SearchBox from '../../../../components/SearchBox';
import TitleWithButtons from '../../../../components/TitleWithButtons';

import api from '../../../../services/api';
import { Container } from '../../../../styles/PageStyles/Create';

interface CommonItem {
  code: number;
  description: string;
  label?: string;
  value?: number;
}

interface TipoItem {
  code: string;
  description: string;
  label?: string;
  value?: string;
}

interface AttributeItem {
  codigo: number;
  descricao: string;
  categoria: string;
  tipo: string;
  codigoPai: number;
  idParceiro: string;
}

const CreateAttribute: React.FC = () => {
  const history = useHistory();
  const { ToastError, Toastsuccess } = useToast();
  const inputsBox = useRef<FormHandles>(null);
  const [categoria, setCategoria] = useState([] as CommonItem[]);
  const [tipoList, setTipoList] = useState([] as TipoItem[]);

  const handleSave = useCallback(() => {
    inputsBox.current?.submitForm();
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          descricao: Yup.string().required(
            'É necessário preencher o descricao'
          ),
          tipo: Yup.string().required('É necessário preencher o tipo'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('Atributos ', {
          codigoPai: data.codigoPai,
          descricao: data.descricao,
          ativo: true,
          tipo: data.tipo,
        });

        Toastsuccess({
          message: 'Attribute  criado com sucesso',
        });

        history.push('/Attribute');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          inputsBox.current?.setErrors(errors);
        }

        ToastError({
          message: 'Ocorreu um erro!',
        });
      }
    },
    [ToastError, Toastsuccess, history]
  );

  const handleBack = useCallback(() => {
    history.push('/Attribute');
  }, [history]);

  const handleReset = useCallback(() => {
    inputsBox.current?.reset();
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        const data = {
          codigo: 0,
          titulo: '',
          tipo: 'Grupo',
        };

        const categoriaResponse = await api.patch('attribute', {
          ...data,
        });

        const modifiedCategoriaList = categoriaResponse.data.map(
          (item: AttributeItem) => ({
            value: item.codigo,
            label: item.categoria,
          })
        );

        setCategoria([...modifiedCategoriaList] as CommonItem[]);
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro!',
        });
      }
    }

    getData();
  }, [ToastError]);

  return (
    <Container>
      <Breadcrumb title="Atributo" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Cadastro`}
      </Breadcrumb>

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Novo Attribute "
              reset
              back
              save
              handleReset={handleReset}
              handleBack={handleBack}
              handleSave={handleSave}
            />

            <div className="inputs-box">
              <SearchBox
                searchBoxRef={inputsBox}
                handleSubmit={handleSubmit}
                buttons={false}
                justifycontent="flex-start"
                inputs={[
                  {
                    width: 70,
                    label: 'descricao:',
                    name: 'descricao',
                    placeholder: 'Digite descricao',
                    messageErrorOnBlur: 'Digite um descricao',
                  },
                  {
                    width: 70,
                    label: 'Tipo:',
                    name: 'tipo',
                    placeholder: 'Digite tipo',
                    messageErrorOnBlur: 'Digite um tipo',
                  },
                  {
                    width: 100,
                    label: 'Sub-Categoria:',
                    name: 'codigoPai',
                    type: 'select',
                    placeholder: 'Selecione sub-Categoria',
                    options: categoria,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateAttribute;
