import api from '../../../../services/api';
import { IUsersInputs, ICreateInputs } from '../types/index';

export const handleStoreData = () => {
  return api.get(`/branch`);
};

export const handleUsersData = (data: IUsersInputs) => {
  const params = {
    search: data?.username || null,
  };

  return api.get('/users', { params });
};

export const handleCreateUserData = (data?: ICreateInputs) => {
  const params = {
    first_name: data?.first_name,
    username: data?.username,
    email: data?.email,
    status: data?.status,
    password: data?.password,
    branch_code: Number(data?.branch_id) || undefined,
  };

  return api.post('/users', { ...params });
};

export const handleEditUserData = (idData: number, data?: ICreateInputs) => {
  const params = {
    first_name: data?.first_name,
    username: data?.username,
    email: data?.email,
    status: data?.status,
    password: data?.password,
    branch_code: data?.branch_id || undefined,
  };

  return api.put(`/users/${idData}`, { ...params });
};

export const handleDeleteStore = (deleteCodigo: number) =>
  api.delete(`/users/${deleteCodigo}`);

export const handleEditUserPassword = (userId: number, password: string) => {
  const params = {
    password,
  };

  return api.put(`/users/password/${userId}`, { ...params });
};
