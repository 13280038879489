/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Fade } from '@material-ui/core';

import { ITicketDetailsModalProps } from '../../types';
import { Comment, Container } from './styles';
import TagStatus from '../../../../components/TagStatus';
import DetailsItem from '../TicketsTable/DetailsItem';
import UserIcon from '../../../../assets/svg/userComment.svg';
import { formatDateWithOffset } from '../../../../utils/formatDateWithOffset';

const TicketDetailsTable: React.FC<ITicketDetailsModalProps> = ({ ticket }) => {
  return (
    <Fade in timeout={1000} unmountOnExit>
      <Container>
        <div className="wrapper">
          <div className="details">
            <div className="detailsColumn">
              <div className="detailsContainer">
                <div className="detailsItemsContainer">
                  <DetailsItem title="ID" value={ticket.id} id="redValue" />
                  <DetailsItem title="Pedido" value={ticket.order} />
                  <DetailsItem title="Título" value={ticket.title} />

                  <div className="detailsItem tableFilterCard">
                    <p>Status</p>
                    <TagStatus status={ticket.status} />
                  </div>
                  <DetailsItem
                    title="Data Ocorrência"
                    value={new Date(ticket.createdAt).toLocaleString()}
                  />
                </div>
                <div className="detailsItemsContainer">
                  <DetailsItem title="CPF" value={ticket.documentCpf} />
                  <DetailsItem
                    title="Cliente"
                    value={ticket.clientName || ''}
                  />
                  <DetailsItem
                    title="Usuário"
                    value={ticket.createdByUserName}
                  />
                  <DetailsItem title="Loja" value={ticket.branchName} />
                  <DetailsItem title="Tipo" value={ticket.categoryName} />
                  <DetailsItem title="Área" value={ticket.sectorName} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {ticket.attachment && ticket.attachment.length > 0 && (
          <div className="attachmentSection">
            {ticket.attachment.map((attachment, index) => (
              <a
                key={index}
                href={attachment.urlFile}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={attachment.urlFile}
                  alt={`Attachment thumbnail ${index + 1}`}
                  className="thumbnail"
                />
              </a>
            ))}
          </div>
        )}

        <div className="commentSection">
          {ticket.comments.map((comment, index) => {
            return (
              // @ts-ignore
              <Comment key={index} id={index}>
                <div className="userInfo">
                  <img src={UserIcon} alt="user icon" />
                  <p>{comment.createdByUserName}</p>
                </div>
                <div className="message">
                  <p>{comment.commented}</p>
                </div>
                <div className="date">
                  {formatDateWithOffset(3, false, new Date(comment.createdAt))}
                </div>
              </Comment>
            );
          })}
        </div>
      </Container>
    </Fade>
  );
};

export default TicketDetailsTable;
