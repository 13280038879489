import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { BsHouse } from 'react-icons/bs';
import { FormHandles } from '@unform/core';

import { Container } from '../../../../styles/PageStyles/Create';
import { useToast } from '../../../../hooks/Toast';

import Breadcrumb from '../../../../components/Breadcrumb';
import SearchBox from '../../../../components/SearchBox';
import TitleWithButtons from '../../../../components/TitleWithButtons';

import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

const CreateUser: React.FC = () => {
  const history = useHistory();
  const { ToastError, Toastsuccess } = useToast();
  const inputsBox = useRef<FormHandles>(null);

  useEffect(() => {
    async function getData() {
      try {
        const user = '';
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro!',
        });
      }
    }

    getData();
  }, [ToastError]);

  const handleSave = useCallback(() => {
    inputsBox.current?.submitForm();
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          username: Yup.string().required('É necessário preencher o Login'),
          fullname: Yup.string().required('É necessário preencher o Nome'),
          email: Yup.string()
            .email('É necessário colocar um email válido')
            .required('É necessário preencher o email '),
          password: Yup.string().required('É necessário preencher um password'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('TbUsers', {
          ...data,
        });

        Toastsuccess({
          message: 'Usuário criado com sucesso',
        });

        history.push('/User');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          inputsBox.current?.setErrors(errors);
        }

        ToastError({
          message: 'Ocorreu um erro!',
        });
      }
    },
    [ToastError, Toastsuccess, history]
  );

  const handleBack = useCallback(() => {
    history.push('/User');
  }, [history]);

  const handleReset = useCallback(() => {
    inputsBox.current?.reset();
  }, []);

  return (
    <Container>
      <Breadcrumb title="Usuário" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Cadastro`}
      </Breadcrumb>

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Novo Usuário"
              reset
              back
              save
              handleReset={handleReset}
              handleBack={handleBack}
              handleSave={handleSave}
            />

            <div className="inputs-box">
              <SearchBox
                searchBoxRef={inputsBox}
                handleSubmit={handleSubmit}
                buttons={false}
                justifycontent="flex-start"
                inputs={[
                  {
                    width: 33,
                    label: 'Login:',
                    name: 'username',
                    placeholder: 'Digite Login',
                    messageErrorOnBlur: 'Digite um Login',
                  },

                  {
                    width: 33,
                    label: 'Nome:',
                    name: 'fullname',
                    placeholder: 'Digite seu nome',
                    messageErrorOnBlur: 'Digite um Nome',
                  },
                  {
                    width: 33,
                    label: 'ativo:',
                    name: 'ativo',
                    type: 'switch-button',
                  },
                  {
                    width: 33,
                    label: 'email:',
                    name: 'email',
                    placeholder: 'Digite e-mail',
                    messageErrorOnBlur: 'Digite um Email',
                  },

                  {
                    width: 33,
                    label: 'password:',
                    name: 'password',
                    placeholder: 'Digite o password',
                    messageErrorOnBlur: 'Digite um password',
                  },
                  {
                    width: 33,
                    label: 'Perfil:',
                    name: 'roles',
                    placeholder: 'Digite o Perfil',
                    messageErrorOnBlur: 'Digite um Perfil',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateUser;
