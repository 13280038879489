import api from '../../../../services/api';
import { IStoreInputs } from '../types/index';

export const handleStoreData = (data: IStoreInputs) => {
  const params = {
    search: data?.name || null,
    is_workflow:
      data?.is_workflow === undefined ? false : data?.is_workflow || null,
  };

  return api.get('/branch?', { params });
};

export const handleCreateStoreData = (data?: IStoreInputs) => {
  const params = {
    name: data?.name,
    is_workflow:
      data?.is_workflow === undefined ? false : data?.is_workflow || null,
    feed: data?.idFeed || null,
    code: Number(data?.id_branch),
  };

  return api.post('/branch', { ...params });
};

export const handleEditStoreData = (idData: number, data?: IStoreInputs) => {
  const params = {
    name: data?.name || null,
    is_workflow: data?.is_workflow === undefined ? false : data?.is_workflow,
    feed: data?.idFeed || null,
    id_branch: data?.id_branch || null,
  };

  return api.put(`/branch/${String(idData)}`, { ...params });
};

export const handleDeleteStore = (deleteCodigo: number) =>
  api.delete(`/branch/${deleteCodigo}`);
