import React, { useEffect, useState, useCallback } from 'react';
import { BsHouse } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import Breadcrumb from '../../../components/Breadcrumb';
import SearchBoxComponent from '../../../components/SearchBox';
import PopUpWindow from '../../../components/PopUpWindow';
import Table from '../../../components/Table';
import LoadingComponent from '../../../components/LoadingComponent';
import TitleWithButtons from '../../../components/TitleWithButtons';
import { useToast } from '../../../hooks/Toast';

import api from '../../../services/api';

import {
  ButtonLink,
  Container as PageListStyle,
} from '../../../styles/PageStyles/List';

interface ProductItem {
  codigo: number;
  produto: string;
  titulo: string;
  descricao: string;
  categoriaNome: string;
  categoria: number;
  marca: number;
  marcaNome: string;
  modelo: string;
  peso: number;
  profundidade: number;
  lagura: number;
  altura: number;
  estoque: number;
  preco: number;
  urlImagem: string;
  idParceiro: string;
  dataCadastro: Date;
  ativo: boolean;
  status: boolean;
}

const Products: React.FC = () => {
  const [products, setProducts] = useState([] as ProductItem[]);
  const [loading, setLoading] = useState(true);
  const [deleteProduct, setDeleteProduct] = useState(NaN as number);

  const { ToastError, Toastsuccess } = useToast();
  const history = useHistory();

  useEffect(() => {
    async function getProducts() {
      try {
        setLoading(true);

        const data = {
          produto: '',
          titulo: '',
          marca: '',
          grupo: '',
        };

        const productResponse = await api.patch('product', {
          ...data,
        });

        setProducts([...productResponse.data]);
      } catch (err) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    }

    getProducts();
  }, [ToastError]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        const dataFilter = {
          produto: data.produto,
          titulo: data.titulo,
          marca: '',
          grupo: '',
        };

        const productResponse = await api.patch('product', {
          ...dataFilter,
        });

        setProducts([...productResponse.data]);

        Toastsuccess({
          message: 'Pesquisa feita com sucesso!',
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    },
    [Toastsuccess, ToastError]
  );

  const handleDeleteProduct = useCallback(async () => {
    try {
      if (!deleteProduct) {
        throw new Error('Product  inexistente');
      }

      await api.delete(`Produtos/${deleteProduct}`);

      Toastsuccess({
        message: `Product ${deleteProduct} deletado com sucesso!`,
      });

      setDeleteProduct(NaN);
    } catch (error) {
      ToastError({
        message: 'Ocorreu um erro',
      });
    }
  }, [Toastsuccess, ToastError, deleteProduct]);

  const deleteThisProduct = useCallback(code => {
    setDeleteProduct(code);
  }, []);

  const editProduct = useCallback(
    code => {
      history.push(`/Product/edit/${code}`);
    },
    [history]
  );

  return (
    <PageListStyle>
      <Breadcrumb title="Produto" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Lista`}
      </Breadcrumb>

      {!!deleteProduct && (
        <PopUpWindow
          title="Deseja deletar?"
          handleSubmit={handleDeleteProduct}
          handleCancel={() => setDeleteProduct(NaN)}
        >
          Você tem certeza que deseja deletar?
        </PopUpWindow>
      )}

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Lista de Produtos"
              filterTitle="Opções de Filtro"
              filter
              filterContent={() => (
                <SearchBoxComponent
                  handleSubmit={handleSubmit}
                  justifycontent="flex-start"
                  inputs={[
                    {
                      width: 50,
                      label: 'Produto:',
                      name: 'produto',
                      placeholder: 'Digite o produto',
                      messageErrorOnBlur: 'Digite um produto',
                    },
                    {
                      width: 50,
                      label: 'Titulo:',
                      name: 'titulo',
                      placeholder: 'Digite o titulo',
                      messageErrorOnBlur: 'Digite um titulo',
                    },
                  ]}
                />
              )}
            />

            {loading ? (
              <LoadingComponent />
            ) : (
              <div className="table-box">
                <Table<ProductItem>
                  rows={[
                    ...products.map(item => ({
                      ...item,
                    })),
                  ]}
                  columns={[
                    {
                      title: 'Produto',
                      orderable: true,
                      type: 'string',
                      props: ['produto'],
                      cssProps: {
                        width: '10%',
                      },
                    },
                    {
                      title: 'Titulo',
                      orderable: true,
                      type: 'string',
                      trunc: 40,
                      props: ['titulo'],
                      cssProps: {
                        width: '30%',
                      },
                    },
                    {
                      title: 'Preço',
                      orderable: true,
                      type: 'number',
                      props: ['preco'],
                      cssProps: {
                        width: '5%',
                      },
                    },
                    {
                      title: 'Estoque',
                      orderable: true,
                      type: 'number',
                      props: ['estoque'],
                      cssProps: {
                        width: '5%',
                      },
                    },
                    {
                      title: 'Categoria',
                      orderable: true,
                      type: 'string',
                      props: ['categoriaNome'],
                      cssProps: {
                        width: '15%',
                      },
                    },
                    {
                      title: 'Marca',
                      orderable: true,
                      type: 'string',
                      props: ['marcaNome'],
                      cssProps: {
                        width: '15%',
                      },
                    },

                    {
                      title: 'ativo',
                      orderable: true,
                      type: 'boolean',
                      props: ['ativo'],
                      cssProps: {
                        width: '5%',
                      },
                    },
                    {
                      title: 'Integrado ?',
                      orderable: true,
                      type: 'boolean',
                      props: ['status'],
                      cssProps: {
                        width: '5%',
                      },
                    },
                  ]}
                  paginationOptions={[
                    {
                      value: 30,
                      label: 30,
                    },
                    {
                      value: 50,
                      label: 50,
                    },
                    {
                      value: 100,
                      label: 100,
                    },
                  ]}
                  exportOption
                  defaultSort="Titulo desc"
                  onEditRow={row => {
                    editProduct(row.codigo);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageListStyle>
  );
};

export default Products;
