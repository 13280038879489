import React from 'react';

interface DetailsItemProps {
  title: string;
  value: string | number;
  id?: string;
  card?: boolean;
  showWithZero?: boolean;
  onClick?: () => void;
}

const DetailsItem: React.FC<DetailsItemProps> = ({
  title,
  value,
  id,
  card,
  children,
  showWithZero,
}) => (
  <>
    {value.toString() !== '0' || showWithZero ? (
      <div className="detailsItem tableFilterCard" id={id}>
        {card ? (
          <>
            <p>{value}</p>
            <p>{title}</p>
            {children}
          </>
        ) : (
          <>
            <p>{title}</p>
            <p>{value}</p>
          </>
        )}
      </div>
    ) : (
      ''
    )}
  </>
);

export default DetailsItem;
