/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHandles } from '@unform/core';
import React, { InputHTMLAttributes, useCallback, useRef } from 'react';

import { Button, Divider, GridSize, IconButton } from '@material-ui/core';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import { OptionTypeBase, Styles } from 'react-select';
import { BorderAll } from '@material-ui/icons';
import Filter from './Filter';
import PopUpFilter from './PopUpFilter';
import { Container, Footer, NewAdressButton, Title } from './styles';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * @property *name* = Nome do input, que poderá ser chamado via *HandleForm*
   * @example name: 'hello'
   */
  name: string;
  /**
   * @property *labelPlacement* = Texto para o input.
   * @example labelPlacement: 'foo'
   */
  labelPlacement?: string;
  /**
   * @property *width* = Largura do input.
   * @example width: 100
   */
  width?: GridSize;
  /**
   * @property *label* = Nome por cima do input.
   * @example label: 'Message'
   */
  label?: string;
  /**
   * @property *type* = Tipo do input.
   * @example type: 'select' | 'text' | 'date' | 'switch' | 'checkbox'
   */
  type?: 'text' | 'date' | 'select' | 'switch' | 'checkbox' | 'textarea';
  /**
   * @property *options* = Opções do input *Select*.
   * @example options: [{label: 'Hello', value: 1}, ...]
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  options?: object[];
  /**
   * @property *messageErrorOnBlue* = Apresenta uma mensagem de erro caso a validação utilizando *Yup* esteja incorreta.
   * @example messageErrorOnBlur: 'O campo deve ser preenchido'
   */
  messageErrorOnBlur?: string;
  /**
   * @deprecated
   * @property *iconError* = Define o ícone caso a validação utilizando *Yup* esteja incorreta.
   * @example iconError: <Icon />
   */
  iconError?: string;
  /**
   * @property *sm* = Tamanho do input para dispositivos maiores que 576px
   * usando de referência tabela de 12 colunas.
   * @example sm: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
   */
  sm?: boolean | GridSize;
  /**
   * @property *xl* = Tamanho do input para dispositivos maiores que 1200px
   * usando de referência tabela de 12 colunas.
   * @example xl: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
   */
  xl?: boolean | GridSize;
  /**
   * @property *lg* = Tamanho do input para dispositivos maiores que 992px
   * usando de referência tabela de 12 colunas.
   * @example lg: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
   */
  lg?: boolean | GridSize;
  /**
   * @property *xs* = Tamanho do input para dispositivos menores que 576px
   * usando de referência tabela de 12 colunas.
   * @example xs: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
   */
  xs?: boolean | GridSize;
  /**
   * @property *xs* = Tamanho do input para dispositivos maiores que 768px
   * usando de referência tabela de 12 colunas.
   * @example xs: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
   */
  md?: boolean | GridSize;
  /**
   * @property *isRequired* = Habilita marcação vermelha de input requerido.
   * @example isRequired: true
   * @description Pode passar também um estado para alternância.
   * @example isRequired: required[true | false]
   */
  isRequired?: boolean;
  /**
   * @property *display* = Habilita | Desabilita o input.
   * @example display: true
   * @description Pode passar também um estado para alternância.
   * @example display: display[true | false]
   */
  display?: boolean;
  /**
   * @property *isLoading* = Habilita um loading no input.
   * @example isLoading: true
   * @description Pode passar também um estado para alternância.
   * @example isLoading: loading[true | false]
   */
  isLoading?: boolean | undefined;
  /**
   * @property *isDisabled* = Desabilita o foco no input, deixando inutilizável.
   * @example isDisabled: true
   * @description Pode passar também um estado para alternância.
   * @example isDisabled: disabled[true | false]
   * @description Este parâmetro pode ser usando em conjunto com o isLoading.
   */
  isDisabled?: boolean | undefined;
  /**
   * @property *style* = Pode atribuir mais estilos ao progressBar.
   * @example style={{filter: 'blur(10px)'}}
   */
  isApiSearch?: boolean | undefined;
  /**
   * @property *style* = define se as opções do select abrem quando há algo sendo digitado no input.
   * @example isApiSearch: true;
   */
  styles?: any;
  /**
   * @property *onClickEvent* = Evento adicional de click do tipo checkbox.
   * @example onClickEvent: () => {...}
   */
  onClickEvent?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  /**
   * @property *labelCheckbox* = Nome por ao lado do checkbox.
   * @example labelCheckbox: 'Checked'
   */
  labelCheckbox?: string;
  onChangeEvent?(value: number | any): void;

  isMulti?: boolean;
  inputValue?: string;
  handleInputChange?: (newValue: string, actionMeta: string) => void;
  isHidden?: boolean;
}

interface SearchBoxProps {
  /**
   * @property *inputs* = Inputs de dentro do Search seguindo a interface InputsProps.
   * @example inputs={[... {name: 'input1', ...}]}
   */
  inputs: InputProps[];
  /**
   * @property *hiddenInputs* = Inputs de dentro do `enablePopUp` seguindo a interface InputsProps.
   * @example hiddenInputs={[... {name: 'input1', ...}]}
   */
  hiddenInputs?: InputProps[];
  /**
   * @property *buttons* = Habilita os botões no search.
   * @example buttons={true}
   */
  buttons?: boolean;
  /**
   * @property *enablePopUp* = Habilita o filtro em modo Pop Up, criando um botão quer exibirá um modal com os inputs e `hiddenInputs`*.
   * @example enablePopUp={true}
   */
  enablePopUp?: boolean;
  /**
   * @property *importButton* = Habilita o botão de importar arquivos.
   * @example importButton={true}
   */
  importButton?(): void;
  /**
   * @property *createButton* = Habilita o botão de criar.
   * @example createButton={true}
   */
  createButton?(): void;
  /**
  //  * @property *searchBoxRef* = Referência dos inputs do Search utilizando {@link https://unform.dev/|unform}.
   * @example searchBoxRef={ref}
   */
  searchBoxRef?: React.RefObject<FormHandles>;
  /**
   * @property *titleButtonOnCreate* = Designa um título para o botão *`create`*.
   * @example titleButtonOnCreate="Criar Perfil"
   */
  titleButtonOnCreate?: string;
  /**
   * @property *handleSubmit* = Ação do botão *`submit`*, necessário passar *`data`*.
   * @example handleSubmit={submitFunction}
   */
  handleSubmit?(data: object): void;

  handleAdd?(data: object): void;
  /**
   * @property *handleSubmitWithCancel* = Ação do botão *`cancel`*, necessário passar *`data`*.
   * @example handleSubmitWithCancel={cancelFunction}
   */
  handleSubmitWithCancel?(data: object): void;
  /**
   * @property *submitButton* = Desabilita o botão *`submit`*.
   * @example submitButton={true}
   */
  submitButton?: boolean;
  /**
   * @property *handleCreate* = Ação do botão *`salvar`* ou *`criar`* dentro do Search.
   * @example handleCreate={createFunction}
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleCreate?(data: object): void;
  /**
   * @property *hiddenChildren* = Permite criar inputs dentro de *`Filtros Avançados`*.
   * @example hiddenChildren={() => (...)}
   */
  hiddenChildren?(): void;
  /**
   * @property *cancelSubmit* = Habilita o botão de cancelar que permite limpar os filtros, além de permitir a execução de uma ação de cancelamento.
   * @example cancelSubmit={true}
   */
  cancelSubmit?: boolean;
  /**
   * @property *children* = Poderá criar React.Components.
   */
  children?: React.ReactNode;
  /**
   * @property *rightChildren* = Poderá criar React.Components ao na barra de título do search.
   * @example rightChildren={() => React.ReactNode}
   */
  rightChildren?(): void;
  /**
   * @property *eventClear* = Poderá travar o valor de um input que não sera limpado mesmo que o clear seja acionado.
   * @example eventClear={e => setEvent(!event)}
   */
  eventClear?: (e?: any) => void;
  addButton?: boolean;
  title?: string;
  clearButtonTitle?: string;
  searchButtonTitle?: string;
  titleSubmitButton?: string;
  returnButton?: boolean;
  clickOnReturn?(data: object): void;
  new_buttons?: {
    title: string;
    style: 'secundaryButton' | 'primaryButton' | 'dangerButton';
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }[];
  searchDisabled?: boolean;
  createButtonContent?: React.ReactElement;
  noTitle?: boolean;
  changeTableModeButton?: boolean;
  changeTableModeAction?(): void;
}

/**
 * Componente Search.
 * Cada propriedade age de um determinado modo e algumas propriedades podem depender de outras.
 * @param {data} handleSubmit Ação do botão *`submit`*, necessário passar *`data`*.
 * @param {boolean=} buttons Habilita os botões no search.
 * @param {boolean=} enablePopUp Habilita os filtros avançados, criando um botão quer exibirá um modal com os *`hiddenInputs`*.
 * @param importButton Habilita o botão de importar arquivos.
 * @param {data} handleCreate Ação do botão *`salvar`* ou *`criar`* dentro do Search.
 * @param {boolean=} submitButton Desabilita o botão *`submit`*.
 * @param {refObject} searchBoxRef Referência dos inputs do Search utilizando
 * @param {data} createButton Habilita o botão de criar.
 * @param {data} handleSubmitWithCancel Ação do botão *`cancel`*, necessário passar *`data`*.
 * @param {string=} titleButtonOnCreate Designa um título para o botão *`create`*.
 * @param children Poderá criar React.Components.
 * @param {InputProps} hiddenChildren Permite criar inputs dentro de *`Filtros Avançados`*.
 * @param {InputProps} hiddenInputs Permite criar inputs dentro de *`Filtros Avançados`*.
 * @param {InputProps} inputs Cria inputs a partir de um JSON no formato da interface InputProps.
 * @param {boolean=} cancelSubmit Habilita o botão de cancelar. Este botão permite limpar os filtros.
 * @param rightChildren Poderá criar React.Components ao na barra de título do search.
 */

const SearchBox: React.FC<SearchBoxProps> = ({
  inputs,
  hiddenInputs,
  enablePopUp = false,
  buttons = false,
  importButton,
  searchBoxRef,
  submitButton = false,
  handleSubmit,
  handleSubmitWithCancel,
  cancelSubmit = false,
  createButton,
  titleButtonOnCreate,
  rightChildren,
  addButton,
  handleAdd,
  returnButton,
  clickOnReturn,
  title,
  new_buttons,
  searchDisabled,
  createButtonContent,
  clearButtonTitle,
  searchButtonTitle,
  noTitle,
  eventClear,
  changeTableModeButton,
  changeTableModeAction,
}) => {
  const ownRef = useRef<FormHandles>(null);
  const formRef = searchBoxRef || ownRef;

  const clearInputs = useCallback(
    (data: any) => {
      if (handleSubmitWithCancel) {
        handleSubmitWithCancel(data);
        inputs.map(input => formRef.current?.setFieldValue(input.name, ''));
        hiddenInputs?.map(input =>
          formRef.current?.setFieldValue(input.name, '')
        );
      } else {
        inputs.map(input => formRef.current?.setFieldValue(input.name, ''));
        hiddenInputs?.map(input =>
          formRef.current?.setFieldValue(input.name, '')
        );
      }
    },
    [inputs, formRef, hiddenInputs, handleSubmitWithCancel]
  );

  const onSubmitAdd = useCallback(
    (data: any) => {
      if (handleAdd) {
        handleAdd(data);
      }
    },
    [handleAdd]
  );

  const handleTraceBack = useCallback(
    (data: any) => {
      if (clickOnReturn) {
        clickOnReturn(data);
      }
    },
    [clickOnReturn]
  );

  const openModal = useCallback(() => {
    if (createButton) {
      createButton();
    }
  }, [createButton]);

  const openModalImport = useCallback(() => {
    if (importButton) {
      importButton();
    }
  }, [importButton]);

  // const closeClick = () => {
  //   const el = document.getElementById('filterBox');
  //   if (el?.classList.contains('active')) {
  //     el.classList.remove('active');
  //   }
  // };

  return (
    <Container className="containerSearch">
      {!noTitle && (
        <>
          <Title
            className="searchTitle"
            padding={enablePopUp && title ? '0 0 0 0' : '1.3rem'}
          >
            {returnButton && (
              <div className="returnClick">
                <IconButton
                  style={{ padding: '8px' }}
                  onClick={handleTraceBack}
                  aria-label="Voltar"
                  className="returnButton"
                >
                  <KeyboardBackspaceRoundedIcon color="disabled" />
                </IconButton>
                <h1>{title || 'Pesquisar'}</h1>
              </div>
            )}
            {!returnButton && <h1>{title || 'Pesquisar'}</h1>}
            <div className="containerFilter" style={{ gap: '10px' }}>
              {rightChildren && rightChildren()}
              {importButton && (
                <NewAdressButton>
                  <button
                    type="button"
                    onClick={openModalImport}
                    className="importButton"
                  >
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5833 0V14.1667H9.91667V12.75H14.1667V1.41667H1.41667V12.75H5.66667V14.1667H0V0H15.5833ZM7.08333 9.20833H4.25L7.79167 4.95833L11.3333 9.20833H8.5V17H7.08333V9.20833Z"
                        fill="white"
                      />
                    </svg>

                    <p>Upload de pedido</p>
                  </button>
                </NewAdressButton>
              )}
              {changeTableModeButton && (
                <Button
                  className="viewModeButtonContainer"
                  onClick={changeTableModeAction}
                >
                  <BorderAll />
                </Button>
              )}
              {createButton && (
                <NewAdressButton>
                  <button
                    type="button"
                    onClick={openModal}
                    className="createButton"
                  >
                    {createButtonContent}
                  </button>
                </NewAdressButton>
              )}
              {enablePopUp && (
                <PopUpFilter
                  hiddenInputs={hiddenInputs}
                  inputs={inputs}
                  handleSubmit={handleSubmit}
                  clearButtonTitle={clearButtonTitle}
                  submitButtonTitle={searchButtonTitle}
                />
              )}
            </div>
          </Title>

          {title ? (
            <Divider
              className="dividerSearch"
              style={{ background: '#E1E1E1', marginTop: '24px' }}
            />
          ) : (
            <Divider
              className="dividerSearch"
              style={{ background: '#E1E1E1' }}
            />
          )}
        </>
      )}
      {!enablePopUp && (
        <Filter
          inputs={inputs}
          hiddenInputs={hiddenInputs}
          handleSubmit={handleSubmit}
          searchDisabled={searchDisabled}
          searchRef={searchBoxRef}
          clearButtonTitle={clearButtonTitle}
          submitButtonTitle={searchButtonTitle}
          eventClearButton={eventClear}
        />
      )}

      {buttons && (
        <>
          <Divider
            className="dividerSearch"
            style={{ background: '#E1E1E1' }}
          />
          <Footer className="containerFooter">
            {new_buttons &&
              new_buttons.map(btn => (
                <Button
                  type="button"
                  variant="contained"
                  className={`${
                    btn.style ? btn.style : 'secundaryButton'
                  } buttonFooter`}
                  onClick={btn.onClick}
                >
                  {btn.title}
                </Button>
              ))}
            {/* importButton && (
              <Button
                type="button"
                variant="contained"
                style={{ background: '#c8c8c8' }}
                className="secundaryButton buttonFooter"
                onClick={openModalImport}
              >
                importar
              </Button>
            ) */}
            {addButton && (
              <Button
                className="secundaryButton buttonFooter"
                type="button"
                variant="contained"
                onClick={onSubmitAdd}
                style={{
                  display: `${submitButton ? 'none' : 'block'}`,
                }}
              >
                Adicionar
              </Button>
            )}
            {cancelSubmit && (
              <Button
                className="primaryButton buttonFooter"
                onClick={clearInputs}
                type="button"
                variant="contained"
              >
                Cancelar
              </Button>
            )}
          </Footer>
        </>
      )}
    </Container>
  );
};

export default SearchBox;
