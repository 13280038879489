import styled from 'styled-components';

interface StatusProps {
  type: string;
}

export const Container = styled.div`
  width: 100%;
  padding: 1.5rem;

  .content {
    width: 100%;
    background: #fff;
    border-radius: 12px;
  }

  .table-box {
    padding: 16px;
  }
`;

export const StatusBox = styled.p<StatusProps>`
  padding: 4px;
  background: ${props =>
    (props.type === 'Entregue' && '#c9f7f5') ||
    (props.type === 'Error' && '#FFE2E5') ||
    (props.type === 'info' && '#fff8dd') ||
    (props.type === 'Info.' && '#fff8dd') ||
    (props.type === 'Info' && '#fff8dd')};
  color: ${props =>
    (props.type === 'Entregue' && '#1bc5bd') ||
    (props.type === 'Error' && '#F64E60') ||
    (props.type === 'info' && '#ff9800') ||
    (props.type === 'Info.' && '#ff9800') ||
    (props.type === 'Info' && '#ff9800')};
  border-radius: 7px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  width: ${props =>
    (props.type === 'Entregue' && '70px') ||
    (props.type === 'Error' && '70px') ||
    (props.type === 'info' && '70px') ||
    (props.type === 'Info.' && '70px') ||
    (props.type === 'Info' && '70px')};
`;
