import React, {
  useEffect,
  useRef,
  InputHTMLAttributes,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface inputErrorProps {
  inputName: string;
  message: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: string;
  iconError?: string;
  iconSuccess?: string;
  width?: number;
  handleInputError?(data: inputErrorProps): void;
  messageErrorOnBlur?: string;
  mask?: (
    e: React.FormEvent<HTMLInputElement>
  ) => React.FormEvent<HTMLInputElement>;
}

const TextArea: React.FC<InputProps> = ({
  name,
  iconSuccess,
  iconError,
  handleInputError,
  messageErrorOnBlur,
  mask,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);
  const [success, setSuccess] = useState(false);

  const handleKeyUp = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (mask) {
        mask(e);
      }
    },
    [mask]
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        ref.value = value;
        setSuccess(false);
        clearError();
      },
    });
  }, [fieldName, registerField, clearError]);

  const handleBlur = useCallback(
    event => {
      if (!event.target.value) {
        if (!!handleInputError && !!messageErrorOnBlur) {
          handleInputError({
            inputName: name,
            message: messageErrorOnBlur,
          });
        }
      } else setSuccess(true);
    },
    [handleInputError, messageErrorOnBlur, name]
  );

  return (
    <Container>
      <textarea
        name={name}
        ref={inputRef}
        defaultValue={defaultValue}
        onBlur={handleBlur}
        onFocus={() => {
          clearError();
          setSuccess(false);
        }}
        rows={4}
      />
    </Container>
  );
};

export default TextArea;
