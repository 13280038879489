import React, { createContext, useCallback, useContext, useState } from 'react';

import {
  AuthContextData,
  AuthState,
  IResponseLogin,
  SignInCredentials,
  User,
} from './types';
import api from '../../services/api';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@pdamodules::token');
    const user = localStorage.getItem('@pdamodules::user');
    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {
      token: '',
      user: null,
      role: null,
    } as AuthState;
  });

  const signIn = useCallback(async ({ uid, password }: SignInCredentials) => {
    try {
      const responseLogin = await api.post<IResponseLogin[]>('login', {
        uid,
        password,
      });
      const userToStore = {
        name: responseLogin.data[0].user.first_name,
        login: responseLogin.data[0].user.username,
        active: responseLogin.data[0].active,
        profile: responseLogin.data[0].user,
        branch_code: responseLogin.data[0].user.branch_code,
        token: responseLogin.data[0].auth.token,
        role: responseLogin.data[0].user.role,
      };
      localStorage.setItem('@pdamodules::token', userToStore.token);
      localStorage.setItem('@pdamodules::user', JSON.stringify(userToStore));
      localStorage.setItem('@pdamodules::module', '43');
      localStorage.setItem('@pdamodules::role', userToStore.role);
      localStorage.setItem(
        '@pdamodules::storeId',
        String(userToStore.branch_code)
      );
      localStorage.setItem(
        '@pdamodules::profile',
        userToStore.profile.toString()
      );

      setData({
        token: userToStore.token,
        user: userToStore,
      });
      return userToStore as User;
    } catch (err) {
      console.log(err);
      return {} as User;
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@pdamodules::token');
    localStorage.removeItem('@pdamodules::user');
    localStorage.removeItem('@pdamodules::module');
    localStorage.removeItem('@pdamodules::profile');
    localStorage.removeItem('@pdamodules::storeId');
    localStorage.removeItem('@pdamodules::role');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };
