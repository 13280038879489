import styled from 'styled-components';

interface StatusProps {
  type: string;
}

export const Container = styled.div<{ withOutPadding?: boolean }>`
  width: 100%;
  padding: ${props =>
    props.withOutPadding ? '1.5rem 1.5rem 0 1.5rem' : '0 1.5rem 1.5rem 1.5rem'};
  margin: 0 auto;

  .content {
    width: 100%;
    background: #fff;
    border-radius: 12px;
  }

  .table-box {
    padding: 16px;

    .orderColumn {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;

        color: #464e5f;
      }
    }
    .centerCell {
      display: flex;
      justify-content: center;
    }
  }
`;

export const StatusBox = styled.p<StatusProps>`
  padding: 4px;
  background: ${props =>
    (props.type === 'Concluido' && '#B7C400') ||
    (props.type === 'Pago' && '#242636') ||
    (props.type === 'Enviado' && '#00B1DE') ||
    (props.type === 'Cancelado' && '#e4e6ef') ||
    (props.type === 'Pendente' && '#F26100') ||
    (props.type === 'Enviado SAP' && '#B7C400') ||
    (props.type === 'Confirmado WMS' && '#B7C400') ||
    (props.type === 'Enviado WMS' && '#B7C400') ||
    (props.type === 'Aguardando Faturamento' && '#B7C400') ||
    (props.type === 'Faturado' && '#F29C11')};

  color: white;
  border-radius: 7px;
  font-size: 10px;
  text-align: center;
  width: 140px;
`;

export const TableFilterCardContainer = styled.div<{
  cardProgressValue?: number;
  withPadding?: boolean;
}>`
  display: flex;
  padding: ${props =>
    props.withPadding ? '0 0 35px 0' : '5px 16px 20px 16px'};
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  button {
    all: unset;
    cursor: pointer;
    transition: ease 0.25s;

    &:hover {
      transform: scale(1.025);
    }
  }

  background: #ffffff;

  div {
    background: #ffffff;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    :last-of-type {
      margin-right: 0;
    }
    #results {
      border-color: #0095e8;
    }
    #done {
      border-color: #46be7d;
    }
    #divergent {
      border-color: #d9214e;
    }
    #ongoing {
      border-color: #ffc700;
    }
    #waiting {
      border-color: #f69b11;
    }
    #canceled {
      border-color: #3f4254;
    }
    #pending {
      border-color: #a1a5b7;
    }
    #expeditionCompleted {
      border-color: #4a7dff;
    }
    #resupply {
      border-color: #58244b;
    }
    #totalProgress {
      border-color: ${props => {
        let progressBg = props.cardProgressValue;
        if (progressBg === undefined) {
          progressBg = 10;
        }
        if (progressBg === 100) {
          return '#46BE7D';
        }
        if (progressBg < 50) {
          return '#D9214E';
        }
        if (progressBg <= 99) {
          return '#FFC700';
        }
        return '#F8B44C';
      }};
      position: relative;
      p {
        color: #3f4254;
        z-index: 2;
      }
    }
    .tableFilterCard {
      display: flex;
      flex-direction: column;
      min-width: 140px;
      border: 1px dashed #0095e8;
      border-radius: 6px;
      padding: 12px 15px;
      p {
        user-select: none;
        :first-of-type {
          font-family: 'Roboto';
          font-weight: 900;
          font-size: 24px;
          color: #404040;
        }
        :last-of-type {
          font-family: 'Roboto';
          font-weight: 500;
          font-size: 12px;
          color: #b5b5c3;
        }
      }
      span {
        border-radius: 6px;
        width: ${props => `${props.cardProgressValue}%`};
        top: 0;
        left: 0;
        height: 100%;
        background: ${props => {
          let progressBg = props.cardProgressValue;
          if (progressBg === undefined) {
            progressBg = 10;
          }
          if (progressBg === 100) {
            return '#46BE7D';
          }
          if (progressBg < 50) {
            return '#D9214E';
          }
          if (progressBg <= 99) {
            return '#FFC700';
          }
          return '#F8B44C';
        }};
        position: absolute;
      }
    }
  }
`;

export const TableContainer = styled.div<{ inPopUp?: boolean }>`
  padding: ${props => (props.inPopUp ? '3px 1rem' : '1rem')};
  color: #505050;
  background-color: #fff;
  max-width: 100%;

  .MuiPaper-root
    .MuiAppBar-root
    .MuiAppBar-positionStatic
    .MuiAppBar-colorPrimary
    .MuiPaper-elevation4,
  .MuiAppBar-colorPrimary {
    background-color: #fff !important;
    border-radius: 0px !important;
  }

  & > * {
    width: 100%;
    .actionsContainer {
      gap: 10px;
      padding-top: 24px;
      .MuiIconButton-root {
        padding: 10px !important;
        /* Update colors in light.ts and dark.ts */
        background-color: #f2f2f2 !important;
      }

      .MuiButtonBase-root {
        .MuiIconButton-root {
          padding: 10px !important;
          background-color: #f2f2f2 !important;
          border-radius: none !important;
        }
      }
    }
    .beforeExport {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-right: 17px;
      margin-bottom: 0.7rem;
      .select {
        width: 37%;
      }
      button {
        background-color: #408cfe;
        color: #fff;
      }
    }

    .MuiTabs-flexContainer {
      background-color: #fff;

      .MuiButtonBase-root {
        background-color: #ecf8ff;
        color: #0095e8;
        border-radius: 6px;
        margin-left: 5px;
        border: none;

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    .render-number,
    .render-string {
      font-weight: 400;
      line-height: 18px;
      font-size: 13px;
      font-family: 'Poppins', sans-serif;
      color: #505050;
      padding: 0 8px;
    }

    .render-number {
      text-align: right;
      padding: 0 8px;
    }

    .detailTableWrap {
      display: flex;
      align-items: center;
      margin-bottom: 0.7rem;

      p {
        margin-left: 0.7rem;
      }
    }
  }
`;
