import styled, { keyframes } from 'styled-components';
import { Form as Unform } from '@unform/web';
import ImageBackground from '../../assets/images/background_sync.png';

interface FormProps {
  loading?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100vw;
  height: 100vh;

  .backgroundImage {
    position: relative;

    flex: 30%;
    // max-width: 705px;

    background-image: url(${ImageBackground});
    background-repeat: no-repeat;
    background-position: center left;
    background-position-x: left;
    background-attachment: fixed;
    background-size: cover;

    width: 100%;
    height: 100vh;

    .overlay {
      position: absolute;

      width: 100%;
      height: 100%;

      //background: rgba(255, 0, 0, 1);
      filter: brightness(0.8);

      mix-blend-mode: multiply;
    }

    .background {
      position: relative;
      width: 100%;
      height: 100vh;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;

      .copy {
        color: #a3a3a3;
        font-weight: 400;
        font-size: 18px !important;
        width: 100%;

        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 3rem 2rem;

        .footerImage {
          a {
            color: #f9f9f9;

            & + a {
              margin-left: 2rem;
            }
          }
        }
      }

      .logo {
        position: absolute;
        top: 50%;
        padding: 0 3rem 2rem;

        transform: translateY(-50%);

        img {
          width: 270px;
          height: auto;

          filter: brightness(0%) saturate(0%) contrast(1000%) invert(100%);
        }

        p {
          color: #f9f9f9;
          font-family: Poppins, sans-serif;
          padding-top: 5rem;
        }
      }

      .image {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0 24px;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .content {
    flex: 45%;

    width: 100%;

    .container {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      z-index: 10;

      .form {
        max-width: 400px;
      }

      .footerForm {
        display: none;
        position: absolute;

        bottom: 0;

        padding-bottom: 2rem;

        a {
          color: #3699ff;

          & + a {
            margin-left: 2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1360px) {
    .background {
      .logo {
        img {
          max-width: 220px;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  @media screen and (max-width: 998px) {
    height: 100%;

    .backgroundImage {
      flex: 100%;

      height: 75px;

      background: #262626;
      background-image: none;

      .overlay {
        display: none;
      }

      .background {
        height: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 0 2rem;

        position: initial;
        width: 100%;

        .logo {
          position: initial;
          transform: none;
          padding: initial;

          p {
            display: none;
          }

          img {
            width: 125px;
            height: auto;
          }
        }

        .copy {
          color: #a3a3a3;
          font-weight: 400;
          font-size: 18px !important;
          width: initial;

          padding: 0;
          display: initial;

          .footerImage {
            display: none;
          }
        }
      }
    }

    .content {
      flex: 100%;

      .container {
        padding: 2rem 0rem;

        .form {
          padding: 3rem 2rem;
        }

        .footerForm {
          display: block;
        }
      }
    }
  }
`;

const loadingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Form = styled(Unform)<FormProps>`
  width: 100%;
  margin-top: 48px;
  text-align: center;

  @media screen and (max-width: 610px) {
    margin: 0 auto;
  }

  .input {
    & + .input {
      margin-top: 16px;
    }
  }

  button {
    width: 100%;
    height: 64px;
    border-radius: 15px;
    background: ${props =>
      props.loading ? 'rgba(225, 35, 37, .8)' : '#ff0000'};
    margin-top: 24px;
    color: #fff;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.3s ease-in-out;

    ${props =>
      props.loading
        ? 'null'
        : '&:hover { background: #fff; box-shadow  0px 0px 7px rgba(255, 0, 0, 0.25); color: #FF3434; }'};

    svg {
      animation: ${loadingAnimation} 1.5s linear infinite;
    }
  }

  a {
    display: block;
    margin-top: 32px;
    color: #2b2b2b;
    font-weight: 700;
  }

  @media screen and (max-width: 810px) {
    margin-top: 32px;
  }
`;
