import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { BsHouse } from 'react-icons/bs';
import { FormHandles } from '@unform/core';

import { Container } from '../../../../styles/PageStyles/Edit';
import { useToast } from '../../../../hooks/Toast';

import Breadcrumb from '../../../../components/Breadcrumb';
import SearchBox from '../../../../components/SearchBox';
import TitleWithButtons from '../../../../components/TitleWithButtons';

import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

const CreateLog: React.FC = () => {
  const history = useHistory();
  const { ToastError, Toastsuccess } = useToast();
  const { code } = useParams<Record<string, string | undefined>>();
  const inputsBox = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    if (!Number(code)) {
      history.push('/Log');
    }

    async function getData() {
      try {
        setLoading(true);

        const userResponse = await api.get(`Logs/${code}`);

        inputsBox.current?.setData({
          ...userResponse.data,
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro!',
        });
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [ToastError, code, history]);

  useEffect(() => {
    if (resetPassword) {
      inputsBox.current?.submitForm();
      setResetPassword(false);
    }
  }, [resetPassword]);

  const handleSave = useCallback(() => {
    inputsBox.current?.submitForm();
  }, []);

  const handleBack = useCallback(() => {
    history.push('/Log');
  }, [history]);

  const submit = useCallback(
    async data => {
      const response = await api.put('TbLogs', {
        codidt: code,
        ...data,
      });

      return response;
    },
    [code]
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('É necessário preencher o name'),
          type: Yup.string().required('É necessário preencher o type'),
          value: Yup.string().required('É necessário preencher o value'),
          source: Yup.string().required('É necessário preencher o source'),
          level: Yup.string().required('É necessário preencher o level'),
          message: Yup.string().required('É necessário preencher o message'),
          exception: Yup.string().required(
            'É necessário preencher o exception'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        submit(data);

        Toastsuccess({
          message: 'Log editado com sucesso',
        });

        history.push('/Log');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          inputsBox.current?.setErrors(errors);
        }

        ToastError({
          message: 'Ocorreu um erro cccc!',
        });
      }
    },
    [ToastError, Toastsuccess, history, submit]
  );

  return (
    <Container>
      <Breadcrumb title="Log" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Monitoramento  > Detalhe`}
      </Breadcrumb>

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Detalhe de Log"
              back
              handleSave={handleSave}
              handleBack={handleBack}
            />
            <div className="inputs-box">
              <SearchBox
                searchBoxRef={inputsBox}
                handleSubmit={handleSubmit}
                buttons={false}
                inputs={[
                  {
                    width: 33,
                    label: 'name:',
                    name: 'name',
                    placeholder: 'Digite name',
                    messageErrorOnBlur: 'Digite um name',
                  },
                  {
                    width: 33,
                    label: 'type:',
                    name: 'type',
                    placeholder: 'Digite type',
                    messageErrorOnBlur: 'Digite um type',
                  },
                  {
                    width: 33,
                    label: 'value:',
                    name: 'value',
                    placeholder: 'Digite value',
                    messageErrorOnBlur: 'Digite um value',
                  },
                  {
                    width: 33,
                    label: 'source:',
                    name: 'source',
                    placeholder: 'Digite source',
                    messageErrorOnBlur: 'Digite um source',
                  },
                  {
                    width: 33,
                    label: 'timestamp:',
                    name: 'timestamp',
                    placeholder: 'Digite timestamp',
                    messageErrorOnBlur: 'Digite um timestamp',
                  },
                  {
                    width: 33,
                    label: 'exception:',
                    name: 'exception',
                    placeholder: 'Digite exception',
                    messageErrorOnBlur: 'Digite um exception',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateLog;
