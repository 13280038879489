import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
`;

export const WindowContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  background: rgba(14, 14, 14, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  .popup {
    width: 870px;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-direction: column;

    .popupHeader {
      width: 100%;

      border-bottom: 1px solid #e5eaee;
      padding-bottom: 0.7rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 1rem;

      padding-right: 1.5rem;
      padding-left: 1.5rem;
      padding-top: 1.5rem;

      h5 {
        font-weight: 500;
        font-size: 14px;
        color: #212121;
        line-height: 21px;
      }

      .contentRightGrid {
        button {
          padding: 0;
          border-radius: none;
          background: none;
        }
      }
    }

    .informations-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0.7rem 0;
      border-bottom: 1px solid #e5eaee;
      padding-bottom: 0.7rem;
      flex-wrap: wrap;

      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
    .popupFooter {
      display: flex;
      width: 100%;
      margin-top: 1rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      padding-bottom: 1.5rem;

      .buttons {
        margin-left: auto;

        button {
          width: 88px;
          height: 48px;
          border-radius: 4px;
          font-weight: 600;
          color: #989bae;
          background: #e4e6ef;
          transition: background 0.3s;

          &:hover {
            background: ${darken(0.05, '#fff')};
          }

          & + button {
            margin-left: 16px;
          }

          &.submit {
            background: #3699ff;
            color: #fff;

            &:hover {
              background: ${darken(0.05, '#3699ff')};
            }
          }
        }
      }
    }
  }
`;

export const TitleContent = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #3699ff;
  margin-bottom: 0.5rem;
`;

export const TitleInfo = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #464e5f;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 16px;
    line-height: 27px;
    opacity: 1;
    margin-top: 4px;

    &.red {
      color: #f64e60;
    }

    &.green {
      color: #1bc5bd;
    }
  }
`;
