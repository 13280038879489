// TODO: User: admin@pda.com.br Password: 123456

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { BiLoaderAlt } from 'react-icons/bi';

import Logo from '../../assets/images/logo.png';
import EmailIcon from '../../assets/svg/email.svg';
import EmailIconError from '../../assets/svg/email-error.svg';
import PasswordIcon from '../../assets/svg/password.svg';
import PasswordIconError from '../../assets/svg/password-error.svg';

import { useAuth } from '../../hooks/Auth/index';
import { useToast } from '../../hooks/Toast';

import { Container, Form } from './styles';
import Input from '../../components/Input';
import getValidationErrors from '../../utils/getValidationErrors';

interface SignInFormData {
  uid: string;
  password: string;
}

interface SignInResponse {
  data: {
    changePassword: boolean;
  };
}

const SignIn: React.FC = () => {
  const { signIn, signOut } = useAuth();
  const { ToastError } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);
        const schema = Yup.object().shape({
          uid: Yup.string().required('Seu nome ou email é obrigatório'),
          password: Yup.string().required('A sua senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        signIn({
          uid: data.uid,
          password: data.password,
        }).then((res: any) => {
          setLoading(false);
          history.push('/Order');
        });
      } catch (error) {
        setLoading(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        ToastError({
          message: 'Ocorreu um erro, tente novamente mais tarde.',
        });
      }
    },
    [signIn, history, ToastError]
  );

  useEffect(() => {
    signOut();
  }, [signOut]);

  return (
    <Container>
      <div className="backgroundImage">
        <div className="overlay" />
        <div className="background">
          <div className="logo">
            <img src={Logo} alt="Logo" />
            <p>
              O Portal Oms Loja, foi desenvolvido para permitir de maneira ágil
              e simples gerecial os pedidos na loja fisica.
            </p>
          </div>
          <div className="copy">
            <p>© PDA Soluções</p>
            <div className="footerImage">
              <a href="terms">Versão 3.17</a>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="form">
            <h4>Faça login para entrar na plataforma.</h4>
            <Form
              onSubmit={handleSubmit}
              ref={formRef}
              loading={loading || undefined}
            >
              <div className="input">
                <Input
                  icon={EmailIcon}
                  iconError={EmailIconError}
                  name="uid"
                  placeholder="Seu nome ou email"
                />
              </div>
              <div className="input">
                <Input
                  icon={PasswordIcon}
                  iconError={PasswordIconError}
                  type="password"
                  name="password"
                  placeholder="Sua senha"
                />
              </div>
              <button type="submit">
                {loading ? <BiLoaderAlt size={24} color="#fff" /> : 'Acessar'}
              </button>
            </Form>
          </div>
          <div className="footerForm">
            <a href="terms">Termos de uso</a>
            <a href="help">Help</a>

            <a href="contact">Contato</a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SignIn;
