import React, { useCallback } from 'react';
import { useToast } from '../../../../hooks/Toast';
import PopUpWindow from '../../../../components/PopUpWindow';
import { handleDeleteStore } from '../api/Store.api';
import { IDeleteStoreProps } from '../types/index';

const DeleteStore: React.FC<IDeleteStoreProps> = ({
  handleClose,
  rechargeStoreData,
  currentValue,
}) => {
  const { Toastsuccess, ToastError } = useToast();
  const hanldleDelete = useCallback(
    codeStore => {
      handleDeleteStore(codeStore)
        .then(() => {
          Toastsuccess({
            message: `A loja ${currentValue.name} foi excluída com sucesso!`,
          });
          handleClose('delete');
        })
        .catch(err => {
          ToastError({
            message: `${err} não foi possivel concluir a exclusão!`,
          });
        })
        .finally(() => {
          rechargeStoreData({
            search: null,
            is_workflow: false,
          });
          handleClose('delete');
        });
    },
    [
      ToastError,
      Toastsuccess,
      currentValue.name,
      handleClose,
      rechargeStoreData,
    ]
  );

  return (
    <PopUpWindow
      title={`Deseja exclui a loja ${currentValue.name}`}
      handleCloseModal={() => handleClose('delete')}
      handleSubmit={() => hanldleDelete(currentValue.id)}
    />
  );
};
export default DeleteStore;
