import React, { useEffect, useState, useCallback } from 'react';
import { BsHouse } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import Breadcrumb from '../../../components/Breadcrumb';
import SearchBoxComponent from '../../../components/SearchBox';
import PopUpWindow from '../../../components/PopUpWindow';
import Table from '../../../components/Table';
import LoadingComponent from '../../../components/LoadingComponent';
import TitleWithButtons from '../../../components/TitleWithButtons';
import { useToast } from '../../../hooks/Toast';
import SearchIcon from '../../../assets/svg/magnify.svg';

import api from '../../../services/api';

import { StatusBox } from './styles';

import {
  ButtonLink,
  Container as PageListStyle,
} from '../../../styles/PageStyles/List';
import DetailLog from './DetailLog';

interface LogItem {
  codidt: number;
  name: string;
  type: string;
  value: string;
  source: string;
  timestamp: Date;
  level: string;
  message: string;
  exception: string;
  timestamputcInsert: Date;
}

const Logs: React.FC = () => {
  const [logs, setLogs] = useState([] as LogItem[]);
  const [loading, setLoading] = useState(true);
  const [deleteLog, setDeleteLog] = useState(NaN as number);

  const [detailLogItem, setDetailLogItem] = useState({} as LogItem);

  const [logDetail, setLogDetail] = useState(NaN as number);

  const { ToastError, Toastsuccess } = useToast();
  const history = useHistory();

  useEffect(() => {
    async function getLogs() {
      try {
        setLoading(true);

        const data = {};

        const logResponse = await api.get('Logs', {
          ...data,
        });

        setLogs([...logResponse.data]);
      } catch (err) {
        ToastError({
          message: 'Ocorreu um erro xxxx',
        });
      } finally {
        setLoading(false);
      }
    }

    getLogs();
  }, [ToastError]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        const logResponse = await api.get('Logs', {
          ...data,
        });

        setLogs([...logResponse.data]);

        Toastsuccess({
          message: 'Pesquisa feita com sucesso!',
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro',
        });
      } finally {
        setLoading(false);
      }
    },
    [Toastsuccess, ToastError]
  );

  const handleDeleteLog = useCallback(async () => {
    try {
      if (!deleteLog) {
        throw new Error('Log inexistente');
      }

      await api.delete(`Logs/${deleteLog}`);

      Toastsuccess({
        message: `Log ${deleteLog} deletado com sucesso!`,
      });

      setDeleteLog(NaN);
    } catch (error) {
      ToastError({
        message: 'Ocorreu um erro',
      });
    }
  }, [Toastsuccess, ToastError, deleteLog]);

  const deleteThisLog = useCallback(code => {
    setDeleteLog(code);
  }, []);

  const editLog = useCallback(logItem => {
    // history.push(`/Log/edit/${code}`);
    setDetailLogItem(logItem);
    setLogDetail(logItem.codidt);
  }, []);

  return (
    <PageListStyle>
      <Breadcrumb title="Logs" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Lista`}
      </Breadcrumb>

      {!!deleteLog && (
        <PopUpWindow
          title="Deseja deletar?"
          handleSubmit={handleDeleteLog}
          handleCancel={() => setDeleteLog(NaN)}
        >
          Você tem certeza que deseja deletar?
        </PopUpWindow>
      )}

      {!!logDetail && (
        <DetailLog
          title="Detalhe do Log"
          handleCancel={() => setLogDetail(NaN)}
          detailLog={detailLogItem}
        />
      )}

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Lista de Logs Geral"
              filterTitle="Opções de Filtro"
              filter
              filterContent={() => (
                <SearchBoxComponent
                  handleSubmit={handleSubmit}
                  justifycontent="flex-start"
                  inputs={[
                    {
                      width: 50,
                      label: 'name:',
                      name: 'name',
                      placeholder: 'Digite o name',
                      messageErrorOnBlur: 'Digite um name',
                    },
                    {
                      width: 50,
                      label: 'type:',
                      name: 'type',
                      placeholder: 'Digite o type',
                      messageErrorOnBlur: 'Digite um type',
                    },
                  ]}
                />
              )}
            />

            {loading ? (
              <LoadingComponent />
            ) : (
              <div className="table-box">
                <Table<LogItem>
                  rows={[
                    ...logs.map(item => ({
                      ...item,
                    })),
                  ]}
                  exportOption
                  columns={[
                    {
                      title: 'Id',
                      orderable: true,
                      type: 'number',
                      props: ['codidt'],
                      cssProps: {
                        width: '5%',
                      },
                    },
                    {
                      title: 'Data',
                      orderable: true,
                      type: 'datetime',
                      props: ['timestamp'],
                      cssProps: {
                        width: '15%',
                      },
                    },
                    {
                      title: 'Status',
                      orderable: true,
                      type: 'string',
                      props: ['type'],
                      cssProps: {
                        width: '8%',
                        padding: '8px',
                        textAlign: 'left',
                      },
                      renderItem: row => {
                        return (
                          <StatusBox type={row.type}>{row.type}</StatusBox>
                        );
                      },
                    },
                    {
                      title: 'Fução',
                      orderable: true,
                      type: 'string',
                      props: ['value'],
                      cssProps: {
                        width: '10%',
                      },
                    },
                    {
                      title: 'Nome',
                      orderable: true,
                      type: 'string',
                      props: ['name'],
                      cssProps: {
                        width: '15%',
                      },
                    },
                    {
                      title: 'Descrição',
                      orderable: true,
                      type: 'string',
                      props: ['source'],
                      cssProps: {
                        width: '50%',
                      },
                    },
                    {
                      title: 'Detalhe',
                      orderable: false,
                      type: 'string',
                      props: ['codidt'],
                      cssProps: {
                        width: '5%',
                        padding: '0px',
                      },
                      renderItem: row => {
                        return (
                          <div className="row-actions-button-detail">
                            <button
                              type="button"
                              className="action"
                              onClick={row1 => {
                                editLog(row);
                              }}
                            >
                              <img src={SearchIcon} alt="" />
                              <p className="hover-item">Detalhe</p>
                            </button>
                          </div>
                        );
                      },
                    },
                  ]}
                  paginationOptions={[
                    {
                      value: 30,
                      label: 30,
                    },
                    {
                      value: 50,
                      label: 50,
                    },
                    {
                      value: 100,
                      label: 100,
                    },
                  ]}
                  defaultSort="timestamp"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageListStyle>
  );
};

export default Logs;
