import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { BsHouse } from 'react-icons/bs';
import { FormHandles } from '@unform/core';

import getValidationErrors from '../../../../utils/getValidationErrors';
import { useToast } from '../../../../hooks/Toast';

import Breadcrumb from '../../../../components/Breadcrumb';
import SearchBox from '../../../../components/SearchBox';
import TitleWithButtons from '../../../../components/TitleWithButtons';

import api from '../../../../services/api';
import { Container } from '../../../../styles/PageStyles/Create';

const CreateProduct: React.FC = () => {
  const history = useHistory();
  const { ToastError, Toastsuccess } = useToast();
  const inputsBox = useRef<FormHandles>(null);

  const handleSave = useCallback(() => {
    inputsBox.current?.submitForm();
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          produto: Yup.string().required('É necessário preencher o produto'),
          titulo: Yup.string().required('É necessário preencher o titulo'),
          descricao: Yup.string().required(
            'É necessário preencher o descricao'
          ),
          categoria: Yup.string().required(
            'É necessário preencher o categoria'
          ),
          marca: Yup.string().required('É necessário preencher o marca'),
          modelo: Yup.string().required('É necessário preencher o modelo'),
          peso: Yup.string().required('É necessário preencher o peso'),
          profundidade: Yup.string().required(
            'É necessário preencher o profundidade'
          ),
          lagura: Yup.string().required('É necessário preencher o lagura'),
          altura: Yup.string().required('É necessário preencher o altura'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('TbProducts ', {
          ...data,
        });

        Toastsuccess({
          message: 'Product  criado com sucesso',
        });

        history.push('/Product');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          inputsBox.current?.setErrors(errors);
        }

        ToastError({
          message: 'Ocorreu um erro!',
        });
      }
    },
    [ToastError, Toastsuccess, history]
  );

  const handleBack = useCallback(() => {
    history.push('/Product');
  }, [history]);

  const handleReset = useCallback(() => {
    inputsBox.current?.reset();
  }, []);

  return (
    <Container>
      <Breadcrumb title="Product " icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Cadastro`}
      </Breadcrumb>

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Novo Product "
              reset
              back
              save
              handleReset={handleReset}
              handleBack={handleBack}
              handleSave={handleSave}
            />

            <div className="inputs-box">
              <SearchBox
                searchBoxRef={inputsBox}
                handleSubmit={handleSubmit}
                buttons={false}
                justifycontent="flex-start"
                inputs={[
                  {
                    width: 33,
                    label: 'produto:',
                    name: 'produto',
                    placeholder: 'Digite produto',
                    messageErrorOnBlur: 'Digite um produto',
                  },
                  {
                    width: 33,
                    label: 'titulo:',
                    name: 'titulo',
                    placeholder: 'Digite titulo',
                    messageErrorOnBlur: 'Digite um titulo',
                  },
                  {
                    width: 33,
                    label: 'descricao:',
                    name: 'descricao',
                    placeholder: 'Digite descricao',
                    messageErrorOnBlur: 'Digite um descricao',
                  },
                  {
                    width: 33,
                    label: 'categoria:',
                    name: 'categoria',
                    placeholder: 'Digite categoria',
                    messageErrorOnBlur: 'Digite um categoria',
                  },
                  {
                    width: 33,
                    label: 'marca:',
                    name: 'marca',
                    placeholder: 'Digite marca',
                    messageErrorOnBlur: 'Digite um marca',
                  },
                  {
                    width: 33,
                    label: 'modelo:',
                    name: 'modelo',
                    placeholder: 'Digite modelo',
                    messageErrorOnBlur: 'Digite um modelo',
                  },
                  {
                    width: 33,
                    label: 'peso:',
                    name: 'peso',
                    placeholder: 'Digite peso',
                    messageErrorOnBlur: 'Digite um peso',
                  },
                  {
                    width: 33,
                    label: 'profundidade:',
                    name: 'profundidade',
                    placeholder: 'Digite profundidade',
                    messageErrorOnBlur: 'Digite um profundidade',
                  },
                  {
                    width: 33,
                    label: 'lagura:',
                    name: 'lagura',
                    placeholder: 'Digite lagura',
                    messageErrorOnBlur: 'Digite um lagura',
                  },
                  {
                    width: 33,
                    label: 'altura:',
                    name: 'altura',
                    placeholder: 'Digite altura',
                    messageErrorOnBlur: 'Digite um altura',
                  },
                  {
                    width: 33,
                    label: 'urlImagem:',
                    name: 'urlImagem',
                    placeholder: 'Digite urlImagem',
                    messageErrorOnBlur: 'Digite um urlImagem',
                  },
                  {
                    width: 33,
                    label: 'idParceiro:',
                    name: 'idParceiro',
                    placeholder: 'Digite idParceiro',
                    messageErrorOnBlur: 'Digite um idParceiro',
                  },
                  {
                    width: 33,
                    label: 'dataCadastro:',
                    name: 'dataCadastro',
                    placeholder: 'Digite dataCadastro',
                    messageErrorOnBlur: 'Digite um dataCadastro',
                  },
                  {
                    width: 33,
                    label: 'ativo:',
                    name: 'ativo',
                    placeholder: 'Digite ativo',
                    messageErrorOnBlur: 'Digite um ativo',
                  },
                  {
                    width: 33,
                    label: 'status:',
                    name: 'status',
                    placeholder: 'Digite status',
                    messageErrorOnBlur: 'Digite um status',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateProduct;
