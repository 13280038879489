/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { IOrder } from '../../features/Orders/types';
import { Container } from './styles';
import pickupIcon from '../../assets/svg/pickupIcon.svg';
import sentIcon from '../../assets/svg/sentIcon.svg';
import TagStatus from '../TagStatus';

interface TableGridMode {
  data: IOrder[];
  statusList: any[];
  openDetail: (row: IOrder) => void;
}

type GroupedItems = {
  [key: string]: IOrder[];
};

type StatusOrder = {
  [key: string]: number; // Definindo um index signature para aceitar qualquer string como chave e atribuir um número como valor
};

const TableGridMode: React.FC<TableGridMode> = ({
  data,
  statusList,
  openDetail,
}) => {
  const orderIcon = (orderType: string | null) => {
    switch (orderType) {
      case 'Envio':
        return sentIcon;
      case 'Retira':
        return pickupIcon;
      default:
        return sentIcon;
    }
  };

  const statusOrder: StatusOrder = {
    'A Separar': 1,
    'Em Separação': 1,
    'Em Conferência': 2,
    'A Conferir': 2,
    'Aguardando Faturamento': 3,
    Preparado: 4,
    'Em Trânsito': 4,
    Entregue: 5,
    Recebido: 5,
  };

  const groupedItems: GroupedItems = {};

  data.forEach(item => {
    let category: string;
    if (item.status === 'A Separar' || item.status === 'Em Separação') {
      category = '1 - A Separar - Em Separação';
    } else if (
      item.status === 'Em Conferência' ||
      item.status === 'A Conferir'
    ) {
      category = '2 - Em Conferência - A Conferir';
    } else if (item.status === 'Aguardando Faturamento') {
      category = '3 - Aguardando Faturamento';
    } else if (item.status === 'Preparado' || item.status === 'Em Trânsito') {
      category = '4 - Preparado - Em Trânsito';
    } else if (item.status === 'Entregue' || item.status === 'Recebido') {
      category = '5 - Entregue - Recebido';
    } else {
      category = 'Outros'; // Adicione um tratamento para outros status que possam surgir
    }
    if (!groupedItems[category]) {
      groupedItems[category] = [];
    }
    groupedItems[category].push(item);
  });

  const sortedCategories = Object.keys(groupedItems).sort(
    (a, b) => statusOrder[a] - statusOrder[b]
  );

  // Mapear os itens para cada categoria
  const statusWithOrders = sortedCategories.map(category => {
    return {
      label: category,
      orders: groupedItems[category],
    };
  });

  const categoryLabels = [
    'A Separar - Em Separação',
    'Em Conferência - A Conferir',
    'Aguardando Faturamento',
    'Preparado - Em Trânsito',
    'Entregue - Recebido',
  ];

  const formatDateTime = (rawDate: string) => {
    const date = new Date(rawDate)
      .toISOString()
      .split('T')[0]
      .split('-')
      .slice(0, 3)
      .reverse()
      .join('/');
    const time = new Date(rawDate)
      .toISOString()
      .split('T')[1]
      .split(':')
      .slice(0, -1)
      .join(':');
    return `${date} ${time}`;
  };

  return (
    <Container>
      {categoryLabels.map((label, index) => {
        const status = statusWithOrders.find(item =>
          item.label.includes(label)
        );
        if (!status) return null; // Se não houver pedidos para essa categoria, pular

        const ordersCount = status.orders.length;
        return (
          <div className="gridCard" key={label}>
            <div className="gridHeader">
              <p />
              <p>{label}</p>{' '}
              {/* Exibimos apenas o texto do primeiro status sem o número */}
              <p>{ordersCount}</p>
            </div>
            <div className="gridBody">
              {status.orders.map(item => {
                return (
                  <div className="gridContent">
                    <div className="gridContentInfo">
                      <div onClick={() => openDetail(item)}>
                        <img alt="" src={orderIcon(item.typeOrder)} />
                        <p className="itemId">{item.orderNumber}</p>
                      </div>
                      <p>{formatDateTime(item.creationDate)}</p>
                    </div>
                    <div className="gridContentDescription">
                      <p>{item.customerName}</p>
                      <div className="gridStatus">
                        <TagStatus status={item.status} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default TableGridMode;
