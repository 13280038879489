import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './route';

import Users from '../features/Users/pages';
import CreateUser from '../features/Users/pages/CreateUser';
import EditUser from '../features/Users/pages/EditUser';

import Jobs from '../features/Jobs/pages';
import CreateJob from '../features/Jobs/pages/CreateJob';
import EditJob from '../features/Jobs/pages/EditJob';

import Parametros from '../features/Parametros/pages';
import CreateParametro from '../features/Parametros/pages/CreateParametro';
import EditParametro from '../features/Parametros/pages/EditParametro';

import Logs from '../features/Logs/pages';
import EditLog from '../features/Logs/pages/EditLog';

// import Status from '../features/Status/pages';
import EditStatus from '../features/Status/pages/EditStatus';

import DashBoard from '../features/Dashboard/pages';

import NotFoundPage from '../layouts/NotFoundPage';
import NotAuthorized from '../layouts/NotAuthorized';
import SignIn from '../layouts/SignIn';
// import Monitoring from '../features/Monitoring/pages';
import Orders from '../features/Orders';
// import DetailOrder from '../features/Orders/pages/DetailOrder';
import Store from '../features/Register/Store';
import RegisterUser from '../features/Register/RegisterUsers';

import Attributes from '../features/Attributes/pages';
import CreateAttribute from '../features/Attributes/pages/CreateAttribute';
import EditAttribute from '../features/Attributes/pages/EditAttribute';

import Products from '../features/Products/pages';
import CreateProduct from '../features/Products/pages/CreateProduct';
import EditProduct from '../features/Products/pages/EditProduct';
import Occurrence from '../features/Occurrence/pages';

const routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={SignIn} />

        <Route path="/DashBoard" exact component={DashBoard} isPrivate />
        <Route path="/User" exact component={Users} isPrivate />
        <Route path="/User/edit/:code" exact component={EditUser} isPrivate />
        <Route path="/User/create" component={CreateUser} isPrivate />

        <Route path="/Job" exact component={Jobs} isPrivate />
        <Route path="/Job/edit/:code" exact component={EditJob} isPrivate />
        <Route path="/Job/create" component={CreateJob} isPrivate />

        {/* <Route path="/Status" exact component={Status} isPrivate /> */}
        <Route
          path="/Status/edit/:code"
          exact
          component={EditStatus}
          isPrivate
        />

        <Route path="/Parametro" exact component={Parametros} isPrivate />
        <Route
          path="/Parametro/edit/:code"
          exact
          component={EditParametro}
          isPrivate
        />
        <Route path="/Parametro/create" component={CreateParametro} isPrivate />

        <Route path="/Log" exact component={Logs} isPrivate />
        <Route path="/Log/edit/:code" exact component={EditLog} isPrivate />

        {/* <Route path="/Monitoring" component={Monitoring} isPrivate /> */}

        <Route path="/Order" component={Orders} isPrivate />
        <Route path="/Register/Store" component={Store} isPrivate />
        <Route path="/Register/Users" component={RegisterUser} isPrivate />
        {/* <Route
          path="/orders/detail/:code"
          exact
          component={DetailOrder}
          isPrivate
        /> */}

        <Route path="/Attribute" exact component={Attributes} isPrivate />
        <Route
          path="/Attribute/edit/:code"
          exact
          component={EditAttribute}
          isPrivate
        />
        <Route path="/Attribute/create" component={CreateAttribute} isPrivate />

        <Route path="/Product" exact component={Products} isPrivate />
        <Route
          path="/Product/edit/:code"
          exact
          component={EditProduct}
          isPrivate
        />
        <Route path="/Product/create" component={CreateProduct} isPrivate />
        <Route
          path="/Occurence/consultation"
          component={Occurrence}
          isPrivate
        />

        <Route path="/505" component={NotAuthorized} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default routes;
