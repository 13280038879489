import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { BsHouse } from 'react-icons/bs';
import { FormHandles } from '@unform/core';

import { Container } from '../../../../styles/PageStyles/Edit';
import { useToast } from '../../../../hooks/Toast';

import Breadcrumb from '../../../../components/Breadcrumb';
import SearchBox from '../../../../components/SearchBox';
import TitleWithButtons from '../../../../components/TitleWithButtons';

import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

interface CommonItem {
  code: number;
  description: string;
  label?: string;
  value?: number;
}

interface AttributeItem {
  codigo: number;
  descricao: string;
  categoria: string;
  tipo: string;
  codigoPai: number;
  idParceiro: string;
}

const CreateAttribute: React.FC = () => {
  const history = useHistory();
  const { ToastError, Toastsuccess } = useToast();
  const { code } = useParams<Record<string, string | undefined>>();
  const inputsBox = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [resetPassword, setResetPassword] = useState(false);

  const [categoria, setCategoria] = useState([] as CommonItem[]);

  useEffect(() => {
    if (!Number(code)) {
      history.push('/Attribute');
    }

    async function getData() {
      try {
        setLoading(true);

        const userResponse = await api.get(`attribute/${code}`);

        const data = {
          codigo: 0,
          titulo: '',
          tipo: 'Grupo',
        };

        const categoriaResponse = await api.patch('attribute', {
          ...data,
        });

        const modifiedCategoriaList = categoriaResponse.data.map(
          (item: AttributeItem) => ({
            value: item.codigo,
            label: item.categoria,
          })
        );

        setCategoria([...modifiedCategoriaList] as CommonItem[]);

        const modifiedSelectedCategoria = {
          value: userResponse.data[0].codigoPai,
          label: userResponse.data[0].categoriaPai,
        };

        inputsBox.current?.setData({
          ...userResponse.data[0],
          categoria: {
            code: modifiedSelectedCategoria,
          },
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro!',
        });
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [ToastError, code, history]);

  useEffect(() => {
    if (resetPassword) {
      inputsBox.current?.submitForm();
      setResetPassword(false);
    }
  }, [resetPassword]);

  const handleSave = useCallback(() => {
    inputsBox.current?.submitForm();
  }, []);

  const handleBack = useCallback(() => {
    history.push('/Attribute');
  }, [history]);

  const submit = useCallback(
    async data => {
      const response = await api.put('Atributos', {
        codigo: code,
        codigoPai: data.categoria.code,
        descricao: data.descricao,
        ativo: true,
        tipo: data.tipo,
      });

      return response;
    },
    [code]
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          descricao: Yup.string().required(
            'É necessário preencher o descricao'
          ),
          tipo: Yup.string().required('É necessário preencher o tipo'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        submit(data);

        Toastsuccess({
          message: 'Attribute editado com sucesso',
        });

        history.push('/Attribute');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          inputsBox.current?.setErrors(errors);
        }

        ToastError({
          message: 'Ocorreu um erro!',
        });
      }
    },
    [ToastError, Toastsuccess, history, submit]
  );

  return (
    <Container>
      <Breadcrumb title="Atributo" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Edição`}
      </Breadcrumb>

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Editar Atributo"
              back
              save
              handleSave={handleSave}
              handleBack={handleBack}
            />
            <div className="inputs-box">
              <SearchBox
                searchBoxRef={inputsBox}
                handleSubmit={handleSubmit}
                buttons={false}
                inputs={[
                  {
                    width: 70,
                    label: 'Nome:',
                    name: 'descricao',
                    placeholder: 'Digite o nome',
                    messageErrorOnBlur: 'Digite o nome',
                  },
                  {
                    width: 30,
                    label: 'Tipo:',
                    name: 'tipo',
                    placeholder: 'Digite tipo',
                    messageErrorOnBlur: 'Digite um tipo',
                    readOnly: true,
                  },

                  {
                    width: 100,
                    label: 'Sub-Categoria:',
                    name: 'categoria.code',
                    type: 'select',
                    placeholder: 'Selecione sub Categoria',
                    options: categoria,
                  },

                  {
                    width: 50,
                    label: 'idParceiro:',
                    name: 'idParceiro',
                    placeholder: 'id Parceiro',
                    messageErrorOnBlur: 'id Parceiro',
                    readOnly: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateAttribute;
