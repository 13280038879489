import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { BsHouse } from 'react-icons/bs';
import { FormHandles } from '@unform/core';

import { Container } from '../../../../styles/PageStyles/Edit';
import { useToast } from '../../../../hooks/Toast';

import Breadcrumb from '../../../../components/Breadcrumb';
import TranferList from '../../../../components/TransferList';
import SearchBox from '../../../../components/SearchBox';
import TitleWithButtons from '../../../../components/TitleWithButtons';

import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import LoadingComponent from '../../../../components/LoadingComponent';

interface Item {
  description: string;
  code: number;
  label: string;
  value: number;
}

const CreateUser: React.FC = () => {
  const history = useHistory();
  const { ToastError, Toastsuccess } = useToast();
  const { code } = useParams<Record<string, string | undefined>>();
  const inputsBox = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    if (!Number(code)) {
      history.push('/User');
    }

    async function getData() {
      try {
        setLoading(true);

        const userResponse = await api.get(`TbUsers/${code}`);

        inputsBox.current?.setData({
          ...userResponse.data,
        });
      } catch (error) {
        ToastError({
          message: 'Ocorreu um erro!',
        });
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [ToastError, code, history]);

  useEffect(() => {
    if (resetPassword) {
      inputsBox.current?.submitForm();
      setResetPassword(false);
    }
  }, [resetPassword]);

  const handleSave = useCallback(() => {
    inputsBox.current?.submitForm();
  }, []);

  const handleReset = useCallback(() => {
    inputsBox.current?.reset();
  }, []);

  const handleBack = useCallback(() => {
    history.push('/User');
  }, [history]);

  const handleResetPassword = useCallback(() => {
    setResetPassword(true);
  }, []);

  const submit = useCallback(
    async data => {
      const response = await api.put('TbUsers', {
        codidt: code,
        ...data,
      });

      return response;
    },
    [code]
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          username: Yup.string().required('É necessário preencher o Login'),
          fullname: Yup.string().required('É necessário preencher o Nome'),
          email: Yup.string()
            .email('É necessário colocar um email válido')
            .required('É necessário preencher o Nome'),
          password: Yup.string().required(
            'É necessário preencher um fornecedor'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        submit(data);

        Toastsuccess({
          message: 'Usuário editado com sucesso',
        });

        history.push('/User');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          inputsBox.current?.setErrors(errors);
        }

        ToastError({
          message: 'Ocorreu um erro!',
        });
      }
    },
    [ToastError, Toastsuccess, history, submit]
  );

  return (
    <Container>
      <Breadcrumb title="Perfis" icon={<BsHouse size={16} color="#c0c0c6" />}>
        {`Administração > Edição`}
      </Breadcrumb>

      <div className="block">
        <div className="container">
          <div className="content">
            <TitleWithButtons
              title="Editar Usuário"
              back
              save
              handleSave={handleSave}
              handleBack={handleBack}
            />
            <div className="inputs-box">
              <SearchBox
                searchBoxRef={inputsBox}
                handleSubmit={handleSubmit}
                buttons={false}
                inputs={[
                  {
                    width: 33,
                    label: 'Login:',
                    name: 'username',
                    placeholder: 'Digite Login',
                    messageErrorOnBlur: 'Digite um Login',
                  },

                  {
                    width: 33,
                    label: 'Nome:',
                    name: 'fullname',
                    placeholder: 'Digite seu nome',
                    messageErrorOnBlur: 'Digite um Nome',
                  },
                  {
                    width: 33,
                    label: 'ativo:',
                    name: 'ativo',
                    type: 'switch-button',
                  },
                  {
                    width: 33,
                    label: 'email:',
                    name: 'email',
                    placeholder: 'Digite e-mail',
                    messageErrorOnBlur: 'Digite um Email',
                  },

                  {
                    width: 33,
                    label: 'password:',
                    name: 'password',
                    placeholder: 'Digite o password',
                    messageErrorOnBlur: 'Digite um password',
                  },
                  {
                    width: 33,
                    label: 'Perfil:',
                    name: 'roles',
                    placeholder: 'Digite o Perfil',
                    messageErrorOnBlur: 'Digite um Perfil',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateUser;
