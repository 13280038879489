/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useCallback } from 'react';

import { AxiosResponse } from 'axios';
import * as C from '../../../../components/FileUpload/styles';

import uploadLgImg from '../../../../assets/svg/uploadLgImg.svg';
import upload_cadastro_icon from '../../../../assets/svg/upload_cadastro_icon.svg';

import { IImportOrdersModal } from '../../types';
import { uploadFile } from '../../api/orders.api';

import { useToast } from '../../../../hooks/Toast';
import PageMode from '../../../../components/PageMode';

const ImportOrdersModal: React.FC<IImportOrdersModal> = ({
  handleClose,
  isOpen,
  updateOrderTables,
}) => {
  const [file, setFile] = useState();
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  const { Toastsuccess, ToastError } = useToast();

  const saveImage = useCallback(async e => {
    const fileDate = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function () {
      const arrayBuffer = fileDate;
      const array = new Uint8Array(arrayBuffer);
      // const binaryString = String.fromCharCode.apply(null, array);
      console.log(array);
    };
    setFile(fileDate);
  }, []);

  const clearFile = () => setFile(undefined);

  const handleSubmitFile = useCallback(
    async (currentFile: any) => {
      setIsModalLoading(true);
      const fileData = new FormData();
      fileData.append('file', currentFile);
      uploadFile(fileData)
        .then((response: AxiosResponse) => {
          if (response.data === true) {
            Toastsuccess({ message: 'Arquivo importado com sucesso.' });
          }
        })
        .catch((err: any) => {
          ToastError({ message: `Erro ao importar arquivo! ${err}` });
        })
        .finally(() => {
          setIsModalLoading(false);
          handleClose();
          updateOrderTables();
        });
    },
    [ToastError, Toastsuccess, handleClose, updateOrderTables]
  );

  const wrapperFunctionSubmit = useCallback(() => {
    handleSubmitFile(file);
    clearFile();
  }, [file, handleSubmitFile]);

  return (
    <>
      <PageMode
        isModal
        submitButtonIcon
        open={isOpen}
        title="Importar Arquivo"
        submitButtonTitle="Salvar"
        handleSubmit={wrapperFunctionSubmit}
        handleClose={handleClose}
        closeWhenClickOutside
        modalLoading={isModalLoading}
        newButton
        newButtonActions={[
          {
            onClick: () => {
              window.open(
                'https://pdahub.blob.core.windows.net/application/oms/LayOut_Remanejamento_SKU.xlsx',
                '_blank'
              );
            },
            renderItem: () => <p>Baixar planilha padrão</p>,
          },
        ]}
      >
        <C.CenterIt>
          <C.ContentWrapper>
            <C.FileUploadTitle>
              Escolha o arquivo para fazer upload.
            </C.FileUploadTitle>
            <C.FileUploadImage src={uploadLgImg} alt="Upload Image" />
            <input
              type="file"
              multiple
              id="inputFiles"
              style={{ display: 'none' }}
              onChange={saveImage}
            />
            {/* 'accept' -> define o tipo de arquivo que pode ser selecionado. Nesse caso excel */}
            <C.FileUploadButton htmlFor="inputFiles">
              <C.FileUploadImage
                src={upload_cadastro_icon}
                alt="Button icon"
                loading="eager"
              />
              Subir Arquivo
            </C.FileUploadButton>
            {file && (
              // eslint-disable-next-line react/jsx-no-comment-textnodes
              <C.FileNamesBox>
                {/* @ts-ignore */}
                <C.FileName>{file.name}</C.FileName>
              </C.FileNamesBox>
            )}
          </C.ContentWrapper>
        </C.CenterIt>
      </PageMode>
    </>
  );
};

export default ImportOrdersModal;
