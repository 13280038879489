import { createGlobalStyle } from 'styled-components';
import { darken } from 'polished';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    outline: 0;
		border: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 16px 'Poppins', sans-serif;
    letter-spacing: -2%;
  }

  button {
    cursor: pointer;
  }

	a {
		text-decoration: none;
	}

	ul {
		list-style: none;
	}

  .container {
    max-width: 1240px;
    height: 100%;
    margin: 0 auto;
    padding: 0 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 140%;
  }

  h1 {
    font-size: 56px;
  }

  h2 {
    font-size: 48px;
  }

  h3 {
    font-size: 40px;
  }

  h4 {
    font-size: 32px;
  }

  h5 {
    font-size: 24px;
  }

  .w33 {
    width: 33%;
  }

  .w50 {
    width: 49%;
  }

  .w66 {
    width: 66%;
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }

   #page-report{
     display: none;
   }

  #content-report-geral{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width:595px;
  }

  .content-report{
    width:100%;
    height:841px;
    font-size: 9px;
    margin: 0 auto;



    h1{
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
      color: #b5b5c3;
    }

    .content-report-footer {
      display: flex;
      margin-top: 100px;
      align-items: flex-start;
      height: 54px;
      margin-left: 20px;

      .content-report-footer-item {
        height: 54px;

        p{
          text-align:left;
          color: #b5b5c3;
        }
      }


    }

    .table-box {
      width: 98%;
      margin: 0 auto;
      margin-top: 40px;

      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;


    thead{
      tr {
        th {
          padding: 12px 2px;
          text-align: left;
          background-color: #b5b5c3;
          color: #ffff;
        }
      }
    }

    tbody {
      tr {
        td
          {
          padding: 12px 2px;
          color: #b5b5c3;
          border: 1px solid #b5b5c3;

          .volume{
            float: right;
          }
         }
      }
    }


  }
  }

  .button-exportar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    line-height: 18px;
    width: 75px;
    height: 35px;

    margin-top: 3px;
    background: rgb(225, 240, 255);
    color: #3699ff;
    font-size: 12px;
    text-transform: initial;

    &:hover {
      transition: background 0.2s;
      background: ${darken(0.05, '#3699FF')};
      color: #ffff;
    }

    & + button {
      margin-left: 6px;
    }
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #dddddd;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px 0px #f0f0f0;
  }

  .signatureImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 250px;
    }
  }
`;
