import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import PageMode from '../../../../components/PageMode';
import { useToast } from '../../../../hooks/Toast';
import { handleCreateStoreData } from '../api/Store.api';
import { ICreateStoreProps } from '../types';

const EditStore: React.FC<ICreateStoreProps> = ({
  isOpen,
  handleClose,
  rechargeStoreData,
  typesStore,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const editInputsRef = useRef<FormHandles>(null);
  const { Toastsuccess, ToastError } = useToast();

  const hanldleCreate = useCallback(
    (data: any) => {
      handleCreateStoreData(data)
        .then(() => {
          Toastsuccess({
            message: `Loja criada com sucesso!`,
          });
        })
        .catch(err => {
          console.log(err);
          ToastError({ message: `Falha ao criar,loja já existe!` });
          handleClose('edit');
        })
        .finally(() => {
          rechargeStoreData({
            search: null,
            is_workflow: false,
          });
          handleClose('edit');
          setIsModalLoading(false);
        });
    },
    [ToastError, Toastsuccess, handleClose, rechargeStoreData]
  );

  return (
    <PageMode
      isModal
      open={isOpen}
      modalLoading={isModalLoading}
      handleClose={() => handleClose('edit')}
      modalRefObject={editInputsRef}
      submitButtonTitle="Salvar"
      submitButtonIcon
      title="Cadastro Loja"
      handleSubmit={data => hanldleCreate(data)}
      inputs={[
        {
          name: 'id_branch',
          label: 'Código Loja',
          placeholder: 'Digite o código da loja',
          type: 'text',
          isRequired: true,
          xl: 3,
          lg: 3,
          xs: 12,
          md: 6,
          sm: 6,
        },
        {
          name: 'name',
          label: 'Nome',
          isRequired: true,
          placeholder: 'Nome da loja',
          type: 'text',
          xl: 3,
          lg: 3,
          xs: 12,
          md: 6,
          sm: 6,
        },
        {
          name: 'idFeed',
          label: 'ID Feed AJ',
          placeholder: 'ID Feed AJ',
          type: 'text',
          xl: 3,
          lg: 3,
          xs: 12,
          md: 6,
          sm: 6,
        },
        {
          name: 'is_workflow',
          label: 'Tipo Fluxo',
          placeholder: 'Tipo Fluxo',
          isRequired: true,
          type: 'select',
          options: typesStore,
          xl: 3,
          lg: 3,
          xs: 12,
          md: 6,
          sm: 6,
        },
      ]}
    />
  );
};
export default EditStore;
