import React, { useEffect, useState } from 'react';

import { BsHouse } from 'react-icons/bs';
import Chart from 'react-apexcharts';
import { Container } from '../../../styles/PageStyles/List';
import Breadcrumb from '../../../components/Breadcrumb';
import iconDinheiro from '../../../assets/svg/dinheiro.svg';
import iconProduto from '../../../assets/svg/produto.svg';

import { CardBody, Card, Grafico } from './styles';

import api from '../../../services/api';

interface DashboardItem {
  quantidadePedido: number;
  valorPedido: string;
  valorPendenteFaturamento: string;
  valorFaturamento: string;
}

const state = {
  options: {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: [1, 2, 3, 4, 5, 6, 7, 8],
    },
  },
  series: [
    {
      name: 'Pedidos Faturado',
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
    {
      name: 'Pendente Faturamento',
      data: [10, 39, 40, 40, 48, 50, 60, 61],
    },
    {
      name: 'Pedidos Cancelado',
      data: [3, 5, 1, 4, 7, 1, 2, 5],
    },
  ],
};

const DashboardEcommerce: React.FC = () => {
  const [dashboardItens, setDashboardItens] = useState({} as DashboardItem);
  const dashboardRefreshRateInSeconds = 60;
  const [isLoading, setLoading] = useState(true);

  async function getDashboardInformation() {
    try {
      setLoading(true);

      const response = await api.get('order/dashboard');

      setDashboardItens(response.data[0]);

      setLoading(false);
    } catch (error) {
      setDashboardItens({
        quantidadePedido: 0,
        valorPedido: '0',
        valorPendenteFaturamento: '0',
        valorFaturamento: '0',
      });

      setLoading(false);
    }
  }

  useEffect(() => {
    getDashboardInformation();

    setInterval(getDashboardInformation, dashboardRefreshRateInSeconds * 1000);
  }, []);

  return (
    <Container>
      <Breadcrumb
        title="Dashboard"
        icon={<BsHouse size={16} color="#c0c0c6" />}
      >
        {`Administração > Dashboard`}
      </Breadcrumb>
      <div className="container">
        <div className="dashboard">
          <Card>
            <CardBody color="#ffc700" fontColor="#fff;">
              <span className="icon">
                <img src={iconDinheiro} alt="" className="svg-icon-success" />
              </span>
              <div className="boxValor">{dashboardItens.valorPedido}</div>
              <div className="boxTexto">Valor Total Mês</div>
            </CardBody>

            <CardBody color="#fff" fontColor="#80808F;">
              <span className="icon">
                <img src={iconProduto} alt="" />
              </span>
              <div className="boxValor">{dashboardItens.quantidadePedido}</div>

              <div className="boxTexto">Quantidade de Pedidos Mês</div>
            </CardBody>
            <CardBody color="#C9F7F5" fontColor="#1BC5BD;">
              <span className="icon">
                <img src={iconDinheiro} alt="" />
              </span>
              <div className="boxValor">{dashboardItens.valorFaturamento}</div>

              <div className="boxTexto">Valor Faturado Mês</div>
            </CardBody>
            <CardBody color="#FFE2E5" fontColor="#F64E60">
              <span className="icon">
                <img src={iconDinheiro} alt="" />
              </span>
              <div className="boxValor">
                {dashboardItens.valorPendenteFaturamento}
              </div>

              <div className="boxTexto">Valor Pendente de Faturamento</div>
            </CardBody>
          </Card>
        </div>
        {/*
        //Grafico de barra
        <div className="dashboard">
          <Grafico>
            <Chart
              options={state.options}
              series={state.series}
              type="bar"
              width="960"
            />
          </Grafico>
        </div>

        */}
      </div>
    </Container>
  );
};

export default DashboardEcommerce;
