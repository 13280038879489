/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-curly-newline */
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import { Container } from './styles';
import SearchBox from '../../../components/Search';
import { useToast } from '../../../hooks/Toast';
import LoadingComponent from '../../../components/LoadingComponent';
import { IDescriptionStoreProps } from '../../Orders/types';
import {
  getSelectOptions,
  handleEditTicket,
  handleSearchTickets,
} from '../api/occurence.api';
import TicketsTable from '../components/TicketsTable';
import { ITicket, TicketDetailProps } from '../types';
import PageMode from '../../../components/PageMode';
import TicketDetailsTable from '../components/TicketDetailsTable';
import EditTicket from '../components/EditTicket';

const Occurrence: React.FC = () => {
  const searchBoxRef = useRef<FormHandles>(null);
  const editTicketRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [subsidiarys, setSubsidiarys] = useState<IDescriptionStoreProps[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [occurrenceData, setOccurrenceData] = useState<any[]>([]);
  const [disabledStore, setDisabledStore] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editTicket, setEditTicket] = useState(false);
  const [ticketDetailInformation, setTicketDetailInformation] =
    useState<ITicket>({} as ITicket);
  const [ticketDetailsData, setTicketDetailsData] = useState<
    TicketDetailProps[]
  >([]);
  const { Toastsuccess, ToastError } = useToast();
  const user = localStorage.getItem('@pdamodules::user');
  const idStore = localStorage.getItem('@pdamodules::storeId');

  const descriptionStore = subsidiarys.filter(
    store => store.value === Number(idStore)
  );
  const storeId = descriptionStore.map(res => res.value);
  const handleStoreInput = useCallback(() => {
    if (idStore !== null) {
      setDisabledStore(true);
      searchBoxRef.current?.setFieldValue('loja', {
        value: String(storeId),
        label: descriptionStore.map(res => res.label),
      });
    }
  }, [descriptionStore, idStore, storeId]);

  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const dateDay = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const getAllOptions = useCallback(async () => {
    const [statusRes, lojasRes, categoriesRes, sectorsRes] =
      await getSelectOptions();

    setStatus(statusRes);
    setSubsidiarys(lojasRes);
    setCategories(categoriesRes);
    setSectors(sectorsRes);
  }, []);

  const handleSubmit = useCallback(
    async formData => {
      setIsLoading(true);
      setShowTable(false);
      await handleSearchTickets(formData)
        .then(res => {
          console.log(res.data);
          console.log(res.data);
          if (!res.data.length) {
            setOccurrenceData([]);
            ToastError({ message: 'Não foram encontrado ocorrência(s)!' });
          } else {
            setOccurrenceData(res.data);
            Toastsuccess({
              message: `${res.data.length} ocorrência(s) encontrados!`,
            });
            setShowTable(true);
          }
        })
        .catch(err => {
          ToastError({ message: err });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [ToastError, Toastsuccess]
  );

  const handleDetail = async (row: ITicket) => {
    setOpen(true);

    const sortedComments = row.comments.slice().sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });

    setTicketDetailInformation({ ...row, comments: sortedComments });
  };

  const setInitialFields = useCallback(() => {
    searchBoxRef.current?.setFieldValue('initialDate', dateDay);
    searchBoxRef.current?.setFieldValue('finalDate', dateDay);
    handleSubmit({
      initialDate: dateDay,
      finalDate: dateDay,
      loja: idStore,
    });
  }, [dateDay, handleSubmit, idStore]);

  const openEditTicket = () => {
    setEditTicket(true);
  };

  const handleSubmitEdit = useCallback(
    async data => {
      // @ts-ignore
      const userData = JSON.parse(user);

      const adjustedDate = new Date();
      adjustedDate.setHours(adjustedDate.getHours() - 3);

      if (!data.description) {
        ToastError({ message: 'Preencha todos os campos!' });
        return;
      }

      try {
        const ticketEditData = {
          id: ticketDetailInformation.id,
          comment: [
            {
              userId: userData.profile.id,
              newStatus:
                // @ts-ignore
                status.find((i: any) => i.value === data.status)?.label,
              previousStatus:
                // @ts-ignore
                status.find((i: any) => i.value === data.status)?.label,
              sectorId: ticketDetailInformation.sectorId,
              createdAt: adjustedDate,
              commented: data.description,
            },
          ],
        };

        await handleEditTicket(ticketEditData)
          .then(response => {
            if (response.status === 200) {
              Toastsuccess({ message: 'Comentário criado com sucesso!' });
              setTicketDetailInformation(previousState => ({
                ...previousState,
                // @ts-ignore
                status: status.find((i: any) => i.value === data.status)?.label,
                comments: [
                  ...previousState.comments,
                  {
                    commented: data.description,
                    userId: userData.profile.id,
                    createdByUserName: userData.name,
                    createdAt: adjustedDate.toISOString(),
                  },
                ],
              }));
              setEditTicket(false);
              const formData = searchBoxRef.current?.getData();

              handleSubmit(formData);
            }
            console.log('Resposta da requisição:', response);
          })
          .catch(error => {
            console.error('Erro na requisição:', error.message);
          });

        console.log('Formulário enviado com sucesso:', ticketEditData);
      } catch (error) {
        ToastError({ message: 'Erro ao criar ocorrência.' });
        console.error(
          'Erro ao processar a imagem ou enviar o formulário:',
          error
        );
      }
    },
    [
      ToastError,
      Toastsuccess,
      handleSubmit,
      status,
      ticketDetailInformation.id,
      ticketDetailInformation.sectorId,
      user,
    ]
  );

  useLayoutEffect(() => {
    getAllOptions();
  }, [getAllOptions, user]);

  useEffect(() => {
    setInitialFields();
  }, [setInitialFields]);

  useEffect(() => {
    const defaultStatus = status.find(
      (i: any) => i.label === ticketDetailInformation.status
    );
    editTicketRef.current?.setFieldValue('status', defaultStatus);
  }, [editTicket, status, ticketDetailInformation.status]);

  useEffect(() => {
    if (descriptionStore.length) {
      handleStoreInput();
    }
  }, [handleStoreInput, descriptionStore]);

  return (
    <>
      <Container withOutPadding>
        <SearchBox
          handleSubmit={data => handleSubmit(data)}
          searchBoxRef={searchBoxRef}
          searchDisabled={isLoading}
          title="Consulta de Ocorrência"
          inputs={[
            {
              name: 'order',
              label: 'Pedido',
              placeholder: 'Digite o pedido',
              type: 'text',
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'clientName',
              label: 'Cliente',
              placeholder: 'Digite o nome do cliente',
              type: 'text',
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'documentCpf',
              label: 'CPF',
              placeholder: 'Digite o CPF',
              type: 'text',
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'loja',
              label: 'Loja',
              placeholder: 'Selecione a Loja',
              type: 'select',
              options: descriptionStore.length ? descriptionStore : subsidiarys,
              isDisabled: disabledStore,
              xl: 3,
              lg: 3,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'status',
              label: 'Status',
              placeholder: 'Selecione o Status',
              type: 'select',
              options: status,
              xl: 3,
              lg: 3,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'initialDate',
              label: 'Data Inicial:',
              type: 'date',
              defaultValue: dateDay,
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
            },
            {
              name: 'finalDate',
              label: 'Data Final:',
              type: 'date',
              defaultValue: dateDay,
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'categoryId',
              label: 'Tipo',
              placeholder: 'Selecione o tipo',
              type: 'select',
              options: categories,
              xl: 3,
              lg: 3,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'sectorId',
              label: 'Área',
              placeholder: 'Selecione a área',
              type: 'select',
              options: sectors,
              xl: 3,
              lg: 3,
              md: 12,
              sm: 12,
              xs: 12,
            },
          ]}
        />
      </Container>
      {isLoading && <LoadingComponent />}
      {showTable && (
        <TicketsTable
          handleFilter={handleSubmit}
          ticketTableRows={occurrenceData}
          openDetail={handleDetail}
        />
      )}
      <PageMode
        isModal
        modalLoading={modalLoading}
        handleSubmit={openEditTicket}
        open={open}
        submitButtonTitle="Comentário"
        handleClose={() => setOpen(!open)}
        title="Detalhes"
      >
        <TicketDetailsTable
          statusOptions={status}
          ticket={ticketDetailInformation}
        />
      </PageMode>
      <PageMode
        isModal
        modalRefObject={editTicketRef}
        modalLoading={modalLoading}
        open={editTicket}
        handleSubmit={data => handleSubmitEdit(data)}
        submitButtonIcon
        submitButtonTitle="Salvar"
        handleClose={() => setEditTicket(!editTicket)}
        title="Adicionar comentário"
        inputs={[
          {
            name: 'status',
            label: 'Status',
            placeholder: 'Selecione o Status',
            type: 'select',
            options: status,
            xl: 4,
            lg: 4,
            md: 12,
            sm: 12,
            xs: 12,
          },
          {
            name: 'description',
            label: 'Mensagem',
            isRequired: true,
            type: 'textarea',
            xl: 12,
            lg: 12,
            xs: 12,
            md: 6,
            sm: 6,
          },
        ]}
      >
        <EditTicket statusOptions={status} ticket={ticketDetailInformation} />
      </PageMode>
    </>
  );
};
export default Occurrence;
