import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  min-height: 600px;
  display: flex;
  margin: 1.5rem 1.5rem 0 1.5rem;
  gap: 1.5rem;
  border-radius: 5px;
  overflow-x: auto;
  overflow-y: hidden;

  .gridCard {
    position: relative;
    min-width: 280px;
    overflow-y: auto;
    height: 600px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    background-color: #fff;

    .gridHeader {
      position: sticky;
      background-color: #fff;
      top: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;

      p {
        font-size: 0.8rem;
        font-weight: 900;
      }
    }

    .gridBody {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .gridContent {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        .gridContentInfo {
          display: flex;
          gap: 1rem;
          align-items: flex-end;
          justify-content: space-between;

          div {
            cursor: pointer;
            display: flex;
            gap: 0.7rem;
            align-items: flex-end;
          }
        }

        .gridContentDescription {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .gridStatus {
            max-width: 50%;
          }
        }

        .itemId {
          font-weight: 600;
          color: #464e5f;
        }

        p {
          font-size: 0.8rem;
          font-weight: 500;
          color: #b5b5c3;
        }
      }
    }
  }
`;
