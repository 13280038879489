import React from 'react';
import { Fade, Divider } from '@material-ui/core';

import { TableContainer, TableFilterCardContainer } from '../../styles/styles';
import { Table } from '../../../../components/NewTable';
import { IOrderDetailsTableProps, OrderDetailProps } from '../../types';
import DetailsItem from '../OrdersTable/DetailsItem';
import { Container } from './styles';
import pickupIcon from '../../../../assets/svg/pickupIcon.svg';
import sentIcon from '../../../../assets/svg/sentIcon.svg';
import TagStatus from '../../../../components/TagStatus';

const OrderDetailsTable: React.FC<IOrderDetailsTableProps> = ({
  rows,
  order,
}) => {
  return (
    <Fade in timeout={1000} unmountOnExit>
      <Container>
        <div className="wrapper">
          <div className="details">
            <div className="detailsContainer">
              <div className="detailsItemsContainer">
                <DetailsItem title="ID" value={order.orderId} id="redValue" />
                <DetailsItem
                  title="Order"
                  value={order.orderNumber}
                  id="redValue"
                />
                <div className="detailsItem tableFilterCard">
                  <p>Status</p>
                  <TagStatus status={order.status} />
                </div>
                <DetailsItem
                  title="Data"
                  value={new Date(order.creationDate).toLocaleString()}
                />
                <div className="detailsItem tableFilterCard">
                  <p>Tipo Pedido</p>
                  <p>
                    {order.typeOrder === null || order.typeOrder === 'Envio' ? (
                      <>
                        <img alt="" src={sentIcon} />
                        Envio para cliente
                      </>
                    ) : (
                      <>
                        <img alt="" src={pickupIcon} />
                        Retirada
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableFilterCardContainer>
          <div>
            <DetailsItem
              card
              title="Quantidade Total"
              value={order.totals.quantity}
            />
            <DetailsItem
              card
              title="Quantidade Separada"
              value={order.totals.checked}
              showWithZero
              id="done"
            />
            <DetailsItem
              card
              title="Quantidade Conferida"
              value={order.totals.conferred}
              showWithZero
              id=""
            />
            <DetailsItem
              card
              title="Quantidade Excluída"
              value={order.totals.excluded}
              showWithZero
              id="divergent"
            />
          </div>
        </TableFilterCardContainer>
        <div className="detailHeaderDivider">
          <p>Itens</p>
        </div>
        <Divider color="#EBEDF2" />
        <div className="tableWrapper">
          <TableContainer>
            <Table<OrderDetailProps>
              columns={[
                {
                  title: '',
                  type: 'stringCenter',
                  orderable: false,
                  props: [''],
                  cssProps: {
                    width: '5%',
                  },
                  renderItem: row => (
                    <div className="orderImage">
                      <img alt="" src={row.imageUrl} />
                    </div>
                  ),
                },
                {
                  title: 'Produto',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['sku'],
                },
                {
                  title: 'Nome',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['name'],
                },
                {
                  title: 'Categoria',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['category'],
                },
                {
                  title: 'Peças',
                  type: 'number',
                  orderable: true,
                  props: ['quantity'],
                },
                {
                  title: 'Separada',
                  type: 'number',
                  orderable: true,
                  props: ['quantityChecked'],
                },
                {
                  title: 'Conferida',
                  type: 'number',
                  orderable: true,
                  props: ['amountConferred'],
                },
                {
                  title: 'Excluída',
                  type: 'number',
                  orderable: true,
                  props: ['quantityExcluded'],
                },
              ]}
              rows={rows}
              exportList
            />
          </TableContainer>
        </div>
      </Container>
    </Fade>
  );
};

export default OrderDetailsTable;
