import styled from 'styled-components';

export const Container = styled.div`
  textarea {
    border: 1px solid #dadada;
    margin: 0.5rem 0;
    height: 130px;
    width: 100%;
    padding: 0.5rem;
    font-size: 12px;
    line-height: 18px;
    color: #464e5f;
  }
`;
